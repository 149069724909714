import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import RequestTile from '../browse/tile-pane/RequestTile';
import RequestReportCard from '../browse/report-cards/RequestReportCard';
import { Back } from '../../icons/icons';
import { getUsersPrimaryEmailAddress } from '../../config/helperFuns';
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        // marginLeft: 20,
        padding: "25px 0 0 25px"
    },
    tileHeaderProduct: {
        fontSize: theme.palette.browse.tile.headerProduct,
        marginLeft: 15
    },
    tileHeaderManufacturer: {
        fontSize: theme.palette.browse.tile.headerManufacturer,
        marginLeft: 10,
        paddingTop: 5
    }
}));

export default function UserRequests() {
    const styles = useStyles();
    const userCtx = React.useContext(UserContext);
    const [requests, setRequests] = React.useState([]);
    const [tileSelected, setTileSelected] = React.useState();
    
    // Hook used to fetch the user requests from the API server
    React.useEffect(() => {
        // Fetches the user requests from the API-Server
        async function getUserRequests(email){
            const fetchRtn = await fetch(
                window.REACT_APP_API_SERVER_ADDRESS + `/api/request?email=${email}`
            );
            return await fetchRtn.json();
        }

        // getUserRequests(userCtx.user.account.email)
        const emailAddress = getUsersPrimaryEmailAddress(userCtx.user.Email)
        getUserRequests(emailAddress)
        .then(requests => {
            // console.log(requests);
            setRequests(requests);
        })
        .catch(error => {
            console.log("Error ", error);
        });

    }, [userCtx]);

    return (
        <div className={styles.root}>    
            <Grid container>
                <Grid item>
                    <Typography variant="h5" style={{marginTop: 5, marginLeft: 10}}>
                        Requests
                    </Typography>
                </Grid>
            </Grid>
            {
                requests && requests.length ? 
                    tileSelected && tileSelected ? 
                        <Grid container alignItems="center" style={{padding: "0 0 0 0px"}}>
                            {/* Back Button */}
                            <Grid item>
                                <IconButton aria-label="back" onClick={() => setTileSelected()} style={{padding: "10px 10px 10px 10px"}}>
                                    <Back />
                                </IconButton>
                            </Grid>

                            {/* TilePane Header */}
                            <Grid item>
                                <Typography variant="h4" className={styles.tileHeaderProduct}>
                                    {tileSelected.product + " : " + tileSelected.mode}
                                </Typography>        
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" className={styles.tileHeaderManufacturer}>
                                    by {tileSelected.manufacturer}
                                </Typography>
                            </Grid>

                            <RequestReportCard request={tileSelected} />
                        </Grid>
                    :
                        <RequestTile 
                            requests={requests}
                            setTileSelected={setTileSelected}
                            parent="account"
                            loading={false}
                        />
                : null
            }
        </div>
    )
};
