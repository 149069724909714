import React, { useContext } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseBlue, AccordianAction } from '../../icons/icons';
import { DownloadsContext } from '../../contexts/DownloadsContext';
import Description from './Description';

const useStyles = makeStyles((theme) => ({
    description: {
        margin: "16px 0px 0px 16px"
    },
    accordian: {
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
    },
    dismissable: {
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
    }
}));

function BrowseLandingPage({ platform }) {
    const styles = useStyles();
    const [accordionOpen, setAccordionOpen] = React.useState(true);
    const [dismissable, setDismissable] = React.useState(true);

    // Handles downloading the latest fixture library for the currently selected platform
    function download(downloadInfo) {
        const link = document.createElement("a");
        link.href = downloadInfo.url;
        link.click();
    }

    // The dismissable accordion
    const Dismissable = () => {
        return (
            <Accordion 
                className={styles.dismissable}
                square 
                elevation={0}
                onClick={() => setDismissable(!dismissable)}
                expanded={dismissable}
                hidden={!dismissable}
            >
                {<AccordionSummary expandIcon={<CloseBlue/>}>
                    <Typography variant='h5'>Welcome to ETC Fixture Library Hub</Typography>
                </AccordionSummary>}
                <AccordionDetails>
                    <Typography variant='body1'>
                        Here you can download and request fixture profiles for ETC and High End Systems control products. 
                        For further information on how the Fixture Library Hub works please visit the help page.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        )
    }

    // The collapsible accordion
    const PlatformInfo = () => {
        return (
            <Accordion 
                className={styles.accordian}
                square 
                elevation={0}
                expanded={accordionOpen}
            >
                <AccordionSummary 
                    expandIcon={<AccordianAction/>}
                    onClick={() => setAccordionOpen(!accordionOpen)}
                >
                    <Typography variant='h5'>{`Platform - ${platform}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Description platform={platform} download={download}></Description>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <div className={styles.description}>
            <div style={{marginBottom: 10}}>
                <Dismissable />
            </div>
            
            <div style={{marginTop: 10}}>
                <PlatformInfo />
            </div>
        </div>
    )
}

// Hack to wait for the Download Context to load before using loading the page
export default function BrowseLanding({platform}){
    const downloadCtx = useContext(DownloadsContext)
    return  downloadCtx && downloadCtx ? <BrowseLandingPage platform={platform} /> : null;
}
