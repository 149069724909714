import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';

// Jades Custom SVG Icons

const useStyles = makeStyles((theme) => ({
    close: {
        color: theme.palette.icons.primary
    },
    accordianAction: {
        color: theme.palette.icons.primary,
        transform: "rotate(90deg)",
    },
    back: {
        color: theme.palette.icons.secondary,
        display: "inline"
    },
    notAcceptable: {
        fontSize: "1em",
        color: theme.palette.tile.request
    },
    acceptable: {
        fontSize: "1em",
        color: "green"
    },
    calendar: {
        color: theme.palette.icons.secondary,
    },
    white: {
        color: "white"
    }
}));

export function CloseBlue(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.close}>
            <path d='M25,0C11.19,0,0,11.19,0,25s11.19,25,25,25,25-11.19,25-25S38.81,0,25,0Zm11.98,34.45l-2.53,2.53-9.45-9.45-9.45,9.45-2.53-2.53,9.45-9.45L13.02,15.55l2.53-2.53,9.45,9.45,9.45-9.45,2.53,2.53-9.45,9.45,9.45,9.45Z'/>
        </SvgIcon>
    )
}

export function Back(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.back}>
            <path d='M12.5,25L37.5,0V50L12.5,25Z'/>
        </SvgIcon>
    )
}

export function DblBack(){
    const styles = useStyles();
    return(
        <SvgIcon id="a" viewBox="0 0 50 50" className={styles.back}>
            <path id="b" d="M25,25L50,0V50L25,25Z"/>
            <path id="c" d="M0,25L25,0V50L0,25Z"/>
        </SvgIcon>
    )
}

export function AccordianAction(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.accordianAction}>
            <path d='M12.5,25L37.5,0V50L12.5,25Z'/>
        </SvgIcon>
    )
}

export function Add(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.white}>
            <path d='M25,0C11.19,0,0,11.19,0,25s11.19,25,25,25,25-11.19,25-25S38.81,0,25,0Zm12.5,27.5h-10v10h-5v-10H12.5v-5h10V12.5h5v10h10v5Z'/>
        </SvgIcon>
    )
}

export function Download(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.white}>
            <path d='M45.58,17.64h-11.76V0H16.18V17.64H4.42l20.58,20.58,20.58-20.58ZM4.42,44.11v5.89H45.58v-5.89H4.42Z'/>
        </SvgIcon>
    )
}

export function NotAccepted(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.notAcceptable}>
            <path d='M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25,25-11.2,25-25S38.8,0,25,0Zm0,41.67c-2.05,0-3.7-1.66-3.7-3.7s1.66-3.7,3.7-3.7,3.7,1.66,3.7,3.7-1.66,3.7-3.7,3.7Zm3.7-11.11h-7.41V8.33h7.41V30.56Z'/>
        </SvgIcon>
    )
}

export function Accepted(){
    const styles = useStyles();
    return(
        <SvgIcon viewBox='0 0 50 50' className={styles.acceptable}>
            <path d='M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25,25-11.2,25-25S38.8,0,25,0Zm-4.05,37.92l-11.22-11.22,3.67-3.68,7.56,7.56,15.63-15.62,3.67,3.68-19.32,19.28Z'/>
        </SvgIcon>
    )
}

export function Calendar(){
    const styles = useStyles();
    return(
        <SvgIcon id="a" viewBox='0 0 50 50' className={styles.calendar}>
            <path d="M42.57,4.98h-2.64V0h-4.98V4.98H15.05V0h-4.98V4.98H2.49V50H47.51V4.98h-4.94Zm0,4.94v5.25H7.43v-5.25H42.57ZM7.43,45.06V20.1H42.57v24.96H7.43Z"/>
            <rect x="12.56" y="25.12" width="4.98" height="4.98"/>
            <rect x="22.51" y="25.12" width="4.98" height="4.98"/>
            <rect x="32.46" y="25.12" width="4.98" height="4.98"/>
            <rect x="12.56" y="35.07" width="4.98" height="4.98"/>
            <rect x="22.51" y="35.07" width="4.98" height="4.98"/>
            <rect x="32.46" y="35.07" width="4.98" height="4.98"/>
        </SvgIcon>
    )
}

export function BlueCheck(props){
    return(
        <SvgIcon viewBox='0 0 50 50' style={{...props.style}}>
            <polygon points='50 12.23 43.98 6.21 18.4 31.79 6.02 19.41 0 25.42 18.38 43.79 50 12.23'/>
        </SvgIcon>
    )
}


// Social Media Icons
export function Facebook(props){
    return(
        <SvgIcon viewBox='0 0 320 512' style={{...props.style}}>
            <path d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'/>
        </SvgIcon>
    )
}

export function Twitter(props){
    return(
        <SvgIcon viewBox='0 0 512 512' style={{...props.style}}>
            <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'/>
        </SvgIcon>
    )
}

export function YouTube(props){
    return(
        <SvgIcon viewBox='0 0 576 512' style={{...props.style}}>
            <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'/>
        </SvgIcon>
    )
}

export function LinkedIn(props){
    return(
        <SvgIcon viewBox='0 0 448 512' style={{...props.style}}>
            <path d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'/>
        </SvgIcon>
    )
}

export function Instagram(props){
    return(
        <SvgIcon viewBox='0 0 448 512' style={{...props.style}}>
            <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'/>
        </SvgIcon>
    )
}

export function Blog(props){
    return(
        <SvgIcon viewBox='0 0 512 512' style={{...props.style}}>
            <path d='M172.2 226.8c-14.6-2.9-28.2 8.9-28.2 23.8V301c0 10.2 7.1 18.4 16.7 22 18.2 6.8 31.3 24.4 31.3 45 0 26.5-21.5 48-48 48s-48-21.5-48-48V120c0-13.3-10.7-24-24-24H24c-13.3 0-24 10.7-24 24v248c0 89.5 82.1 160.2 175 140.7 54.4-11.4 98.3-55.4 109.7-109.7 17.4-82.9-37-157.2-112.5-172.2zM209 0c-9.2-.5-17 6.8-17 16v31.6c0 8.5 6.6 15.5 15 15.9 129.4 7 233.4 112 240.9 241.5.5 8.4 7.5 15 15.9 15h32.1c9.2 0 16.5-7.8 16-17C503.4 139.8 372.2 8.6 209 0zm.3 96c-9.3-.7-17.3 6.7-17.3 16.1v32.1c0 8.4 6.5 15.3 14.8 15.9 76.8 6.3 138 68.2 144.9 145.2.8 8.3 7.6 14.7 15.9 14.7h32.2c9.3 0 16.8-8 16.1-17.3-8.4-110.1-96.5-198.2-206.6-206.7z'/>
        </SvgIcon>
    )
}

export function Request(props){
    return(
        <SvgIcon viewBox='0 0 50 50' style={{...props.style}} fontSize="large">
            <path d='M8.75,50c-1,0-1.88-.37-2.63-1.13-.75-.75-1.12-1.62-1.12-2.62V3.75c0-1,.38-1.88,1.12-2.62,.75-.75,1.63-1.13,2.63-1.13H31.31l13.69,13.69V46.25c0,1-.37,1.88-1.13,2.62-.75,.75-1.62,1.13-2.62,1.13H8.75ZM29.44,15.37h11.81L29.44,3.75V15.37Zm-14.5,13.38h20.12v-3.75H14.94v3.75Zm0,10.62h20.12v-3.75H14.94v3.75Z'/>
        </SvgIcon>
    )
}