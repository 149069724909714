import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core';
import ProtocalDocsSection from './ProtocolDocsSection';
import { DownloadsContext } from '../../contexts/DownloadsContext';
import { PlatformSettings } from '../../AppSettings';

const styles = {
    paper: {
        padding: 30,
        position: 'sticky',
        margin: "20px 20px 0px 20px",
        top: 20,
    }
}

const formInfo = {
    name: [
        'Name', 
        'Enter your full name.'
    ],
    email: [
        'Email Address', 
        'Enter your email address.'
    ],
    platform: [
        'Console Platform', 
        ''
    ],
    manufacturer: [
        'Manufacturer', 
        'The manufacturer is the maker of the fixture. If manufacturer is unknown or not in the list then please use "Other".'
    ],
    fixtureName: [
        'Fixture Name', 
        'The fixture name is the what you would call the product as if you were specifying it on a show.'
    ],
    mode: [
        'Personality / Mode', 
        'The personality or mode of a fixture describes the DMX profile that the product is operating in. Only one personality or mode per request is allowed. Requests that specify "all" as the personality / mode will be rejected. Examples: Standard, Extended, Reduced, etc.'],
    footprint: [
        'DMX Footprint', 
        'This is the number of DMX channels the fixture will occupy for the specified personality / mode.'
    ],
    private: [
        'Privacy', 
        'Requests marked as \'Private\' are not visible to other users on the fixture sharing website. Only the requester can see the request on their user account page. Furthermore, fixture profiles generated from private requests are not published in full console libraries unless the requester contacts ETC directly after the request is fulfilled. The private option is only recommended for fixture Manufacturers developing unreleased fixtures.'
    ],
    comment: [
        'Comment', 
        'The comment field allows you to add additional notes about your request that are not part of the request submission form.'
    ]
}

// Generic view for most of the help items to use
const HelpView = ({str}) => (
    <div>
        <Typography variant="h5" style={{padding: "0px 0px 10px 0px"}}>{str[0]}</Typography>
        <Typography variant="body1" style={{display: "inline"}}>{str[1]}</Typography>
    </div>
)

// Handles the Platform help text which is dynamic with the choosen platform
const PlatformInfo = ({platform, downloads}) => {

    // Special case to update Eos v3 compatibilty version dynamically
    // Commented out because of FLSHARE-339
    /* if(platform === "Eos v3") {
        // Split the platform to get the Eos version
        const split = platform.split(" ");
        // Filter out the Eosv3 download context
        const ctx = downloads.filter(dl => dl.platform === split[0])[0];
        // Split the download contexts version
        const vSplit = ctx[split[1]].version.split(".");
        // Create a new compatibility version based off the major.minor.path of the downloads contexts version
        const comp_ver = `${vSplit[0]}.${vSplit[1]}.${vSplit[2]}`
        // String replace the compatibility version with the extracted version from the download context
        const comp = PlatformSettings[platform].request.platform_info.compatibility.replace("X.X.X", comp_ver);
        // Update the browse platform help texts compatibility section
        PlatformSettings[platform].request.platform_info.compatibility = comp
    } */
    
    return(
        <>
            <br></br>
            <Box>
                <Typography>{PlatformSettings[platform].request.platform_info.header}</Typography>
                <Box paddingLeft={6} py={2}>
                    <ul>
                        {
                            PlatformSettings[platform].supported_models.map((p, idx) => (
                                <li key={idx} style={{padding: "2px 0px 2px 0px"}}>
                                    <Typography variant='body1'>{p}</Typography>
                                </li>
                            ))
                        }
                    </ul>
                </Box>
                <Typography gutterBottom>{PlatformSettings[platform].request.platform_info.help}</Typography>
                <Typography gutterBottom><b>{PlatformSettings[platform].request.platform_info.compatibility}</b></Typography>
            </Box>
        </>
    )
}

// Handles the Due Date help text which is dynamic with the choosen platform
const DueDate = ({str, platform}) => (
    PlatformSettings[platform].due_date ? 
        <div>
            <Typography variant="body1" style={{display: "inline"}}>
                Please allow up to {PlatformSettings[platform].due_date} days for libraries to be completed. If 
                needed sooner, indicate when it is needed but please understand we may not be able to meet all 
                early request dates due to the number of requests received.
            </Typography>
            <Typography variant="body1" style={{display: "inline", color: "red", marginLeft: 5}}>
                Complex devices may require longer than {PlatformSettings[platform].due_date} days.
            </Typography>
        </div>
    :
        <div>
            <Typography variant="body1" style={{display: "inline"}}>
                If your request needs to be expedited, please use this field to specify a due date. If you 
                require a fixture in less than {PlatformSettings[platform].min_date} days please
                contact <a href='https://www.etcconnect.com/Contact/Technical-Support.aspx'>ETC Technical Support</a>. 
            </Typography>
        </div>
)

// Returns section helper information
function Section({ sectionName, platform }) {
    const downloadsCtx = React.useContext(DownloadsContext);

    switch (sectionName) {
        case 'name':
            return <HelpView str={formInfo['name']} />
        case 'email':
            return <HelpView str={formInfo['email']} />
        case 'platform':
            return <HelpView str={formInfo['platform']} />
        case 'manufacturer':
            return <HelpView str={formInfo['manufacturer']} />
        case 'fixtureName':
            return <HelpView str={formInfo['fixtureName']} />
        case 'mode':
            return <HelpView str={formInfo['mode']} />
        case 'footprint':
            return <HelpView str={formInfo['footprint']} />
        case 'documentation':
            return <ProtocalDocsSection />
        case 'private':
            return <HelpView str={formInfo['private']} />
        case 'dueDate':
            return <>
                <Typography variant="h5" style={{padding: "0px 0px 10px 0px"}}>Due Date</Typography>
                <DueDate str={formInfo['dueDate']} platform={platform} />
            </>
        case 'comment':
            return <HelpView str={formInfo['comment']} />
        // ================================ Platform Info ================================
        case 'ColorSource / EchoTouch':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Cobalt':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Eos v2':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Eos v3':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    {
                        downloadsCtx && downloadsCtx ?
                            <PlatformInfo platform={sectionName} downloads={downloadsCtx}/>
                        :
                            null
                    }
                </>
            )
        case 'Hog 4 v3':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Hog 4 v4':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Mosaic':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Paradigm v2':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Paradigm v3':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Paradigm v4':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        case 'Paradigm v5':
            return (
                <>
                    <HelpView str={formInfo['platform']} />
                    <PlatformInfo platform={sectionName} />
                </>
            )
        default:
            return null;
    }
}

export default function FieldHelper({ section, platform }){
    return (
        section ?
            <Paper elevation={0} style={styles.paper}>
                <Section sectionName={section} platform={platform}/>
            </Paper>
        : 
            null
    )
}
