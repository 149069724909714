import React from "react";
import { makeStyles } from '@material-ui/core';
import { Avatar, Button, Grid, Menu, MenuItem } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
    button: {
        color: "whitesmoke",
        fontSize: "11pt",
        fontFamily: "Frutiger LT Bold",
        paddingLeft: 20,
        paddingRight: 20
    }
}));

export default function LoggedInNav(){
    const styles = useStyles();
    const [userMenuAnchor, setUserMenuAnchor] = React.useState(null);
    const [avatarImg, setAvatarImg] = React.useState(null);
    const userCtx = React.useContext(UserContext);
    
    // Hook used to set the Avatar image from the user context
    React.useEffect(() => {
        if(userCtx.isAuthenticated) {
            if( userCtx.user.HttpsImageUrl ) setAvatarImg(userCtx.user.HttpsImageUrl);
        }
    }, [userCtx]);

    return (
        <Grid item container xs={8} alignItems="center">
            <Grid item xs={10}>
                {/* <Button target="_blank" href="https://etcconnect.com" className={styles.button} style={{paddingLeft: 40}}>
                    ETCConnect
                </Button> */}
                <Button component={Link} to="/" className={styles.button}>
                    Fixture Library Hub
                </Button>
                {/* <Button component={Link} to="/request" className={styles.button}>
                    Request
                </Button> */}
                <Button component={Link} to="/help" className={styles.button}>
                    Help
                </Button>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
                <Button
                    aria-controls="user-menu"
                    aria-haspopup="true"
                    onClick={(e) => setUserMenuAnchor(e.currentTarget)}
                >
                    <Avatar 
                        src={avatarImg}
                        display="inline"
                    >
                    </Avatar>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={userMenuAnchor}
                    keepMounted
                    open={Boolean(userMenuAnchor)}
                    onClose={() => setUserMenuAnchor(null)}
                >
                    <MenuItem component={Link} to="/account" onClick={() => setUserMenuAnchor(null)}>Account</MenuItem>
                    <MenuItem onClick={() => userCtx.logout()}>Logout</MenuItem>
                </Menu>
            </Grid>
        </Grid>
    );
};
