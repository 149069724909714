import React from "react";
import { makeStyles } from '@material-ui/core';
import etcHeaderLogo from "../../assets/logo-header.png";
import LoggedInNav from "./LoggedInNav";
import LoggedOutNav from "./LoggedOutNav";
import { AppBar, Grid, IconButton, Toolbar } from "@material-ui/core";
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 0
    },
    img: {
        margin: 7, 
        padding: 0
    }
}));

export default function Navbar() {
    const styles = useStyles();
    const userCtx = React.useContext(UserContext);

    return (
        <AppBar position="static">
            <Toolbar component="nav" style={{padding: 0, minWidth: 916}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={1} container justifyContent="flex-start" alignItems="center" wrap="nowrap">
                        <IconButton target="_blank" href="https://etcconnect.com" className={styles.button}>
                            <img className={styles.img} src={etcHeaderLogo} alt="ETC Logo"/>
                        </IconButton>
                    </Grid>
                    {
                        userCtx && userCtx.isAuthenticated ?
                            <LoggedInNav/>
                       :
                            <LoggedOutNav/>
                    }
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
