import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import theme from '../../../AppTheme';
import ProfileTile from './ProfileTile';
import RequestTile from './RequestTile';
import { Add } from "../../../icons/icons";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0px 0px 0px 16px",
        maxHeight: theme.palette.browse.tile.containerHeight,
        overflowY: "auto",
        overflowX: "hidden",
        '&::-webkit-scrollbar': {
            width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: theme.palette.etc.main,
            outline: '0px solid slategrey'
        }
    },
    officialStream: {
        marginBottom: 10
    },
    streamHeader: {
        paddingLeft: 10,
        fontSize: theme.palette.browse.tile.streamHeader
    },
    actionContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    requestsStream: {
        marginTop: 10,
        borderTopStyle: "solid",
        borderWidth: "thin",
        borderColor: theme.palette.etc.main
    },
    dlButton: {
        borderRadius: 0,
        marginRight: 5,
        backgroundColor: theme.palette.etc.main,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.etc.hover,
        }
    },
    reqButton: {
        marginTop: 10,
        marginRight: 5,
        borderRadius: 0,
        backgroundColor: theme.palette.tile.request,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.tile.hover,
        }
    }
}));

export default function TilePane({ 
    loading, 
    profiles, 
    compounds, 
    requests, 
    setTileSelected, 
    handleDownload, 
    tileProduct, 
    downloadCtx, 
    setReqDialogOpen, 
    setReqData,
    platform,
    manufacturers
}) {        
    const styles = useStyles();
    const [downloadBtnDisabled, setDownloadBtnDisabled] = React.useState(false)

    // Hook used to disable the Download button when no modes are available
    React.useEffect(() => {
        if( profiles.length < 1 ) setDownloadBtnDisabled(true)
        else setDownloadBtnDisabled(false)
    }, [profiles])

    return (
        <Grid className={styles.root}>
            {/* Offical Profiles */}
            <Box className={styles.officialStream}>    
                {/* Header and Download Profiles button */}
                <Grid container className={styles.actionContainer}>
                    <Grid item>
                        <Typography variant="h5" className={styles.streamHeader}>
                            Official Profiles
                        </Typography>
                    </Grid>
                    <Grid item>
                        {   
                            (tileProduct.schema !== "hogv4" && tileProduct.schema !== "hogv5") ?
                                <a 
                                    href={downloadCtx.url} 
                                    style={{textDecoration: "none"}} 
                                    onClick={(e) => {
                                        if (downloadBtnDisabled) e.preventDefault();
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        className={styles.dlButton}
                                        startIcon={<CloudDownloadIcon />}
                                        size="small"
                                        disabled={downloadBtnDisabled}
                                    >
                                        Download Profiles
                                    </Button>
                                </a>
                            :
                                <Button
                                    variant="contained"
                                    className={styles.dlButton}
                                    onClick={handleDownload}
                                    disabled={!profiles.length || loading}
                                    startIcon={<CloudDownloadIcon />}
                                    size="small"
                                >
                                    Download Profiles
                                </Button>
                        }
                    </Grid>
                </Grid>
                {/* Profile/Compound Tiles */}
                <Grid container>
                    {
                        [...profiles, ...compounds].map( (item, idx) => {
                            if( !item.mode ) return null;
                            else {
                                return (
                                    <ProfileTile 
                                        key={idx}
                                        item={item}
                                        color={theme.palette.tile.official}
                                        setTileSelected={setTileSelected}
                                        loading={loading}
                                    />
                                )
                            }
                        })
                    }
                </Grid>
            </Box>

            {/* Requests */}
            <Box className={styles.requestsStream}>    
                {/* Header and Create Request button */}
                <Grid container className={styles.actionContainer}>
                    <Grid item>
                        <Typography variant="h5" className={styles.streamHeader}>
                            Requests
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            className={styles.reqButton}
                            startIcon={<Add />}
                            disabled={loading}
                            size="small"
                            onClick={() => {
                                setReqData({
                                    manufacturer: tileProduct.manufacturer,
                                    product: tileProduct.product,
                                    platform,
                                    manufacturers: manufacturers.map(m => m.title).sort(),
                                    disable: "both"
                                })
                                setReqDialogOpen(true)
                            }}
                        >
                            Create a Request
                        </Button>
                    </Grid>
                </Grid>
                {/* Request Tiles */}
                {
                    requests && requests.length ? 
                        <RequestTile 
                            requests={requests}
                            setTileSelected={setTileSelected}
                            parent="browse"
                            loading={loading}
                        />
                    : null
                }
            </Box>
        </Grid>
    )
}
