import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: "25px 0px 0px 25px"
    },
    listItem: {
        padding: "10px 0px 10px 0px"
    }
})

const numberedItems = [
    "Navigate to the BROWSE page of the Fixture Library Hub",
    "Use the browser to search for the product you want to download. Be sure to select the desired console platform to ensure you are browsing fixture profiles for the console platform you are operating on. Please note that some platforms are available for requesting but not available for browsing.",
    "Click on the product in the product browser. This will present a tile view of all of the official profiles and requests for the product.",
    "Click the download button at the top right of the tile view to the right of the product name. This will download a library file that contains all of the official profiles for that product.",
    "Once the profile is downloaded please refer to the console help documentation for instructions on how to properly install the fixture profile onto your console or PC application."
]

export default function Downloading(){
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container direction='column'>
                <Typography variant='h5' gutterBottom>
                    Downloading Profiles
                </Typography>
                <Typography variant='body1'>
                    To download fixture profiles from the ETC Fixture Library Hub follow the steps below:
                </Typography>
                <ol>
                    {
                        numberedItems.map((item, idx) => (
                            <li key={idx} className={classes.listItem}>
                                <Typography variant='body1'>{item}</Typography>
                            </li>
                        ))
                    }
                </ol>
            </Grid>
        </div>
    )
}