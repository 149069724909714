import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Accepted, NotAccepted } from '../../icons/icons';

const accepted = [
    "Fixture's DMX Protocol Document with DMX channel assignments and values",
    "Fixture's Help Document with DMX Protocol listed in the Index"
]

const unAcceptable = [
    "A list of DMX channels without any associated functions or values",
    "Protocol Documents for \"similar\" fixtures"
]

const fileLimitations = [
    "Only PDF, PNG and JPEG files are permitted to be uploaded.",
    "The maximum upload file size is 10 mb.",
    "Only 3 files are allowed to be submitted."
]

export default function ProtocolDocsSection({ formik }) {
    return (
        <Grid container item xs direction="column">
            <Typography variant="h5" style={{padding: "0px 0px 10px 0px"}}>Protocol Documentation</Typography>

            <Typography variant="body1" gutterBottom>
                Documentation of the DMX protocol is required. You must provide a document that describes the DMX protocol accurately.
            </Typography>

            {/* Acceptable */}
            <Grid container item xs justifyContent="center">
                <Grid item xs={9}>
                    <Grid container alignItems='center'>
                        <Grid item xs={1} style={{margin: "3px 10px 0px 0px"}}>
                            <Accepted/>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">Acceptable Documents:</Typography>
                        </Grid>
                    </Grid>
                    
                    <ul>{
                        accepted.map((str, idx) =>
                            <li key={idx} style={{margin: 5}}>
                                <Typography variant="body2">{str}</Typography>
                            </li>
                        )
                    }</ul>
                </Grid>
            </Grid>

            {/* Unacceptable */}
            <Grid container item xs justifyContent="center">
                <Grid item xs={9}>
                    <Grid container alignItems='center'>
                        <Grid item xs={1} style={{margin: "3px 10px 0px 0px"}}>
                            <NotAccepted/>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">Non Acceptable Documents:</Typography>
                        </Grid>
                    </Grid>
                    <ul>{
                        unAcceptable.map((str, idx) =>
                            <li key={idx} style={{margin: 5}}>
                                <Typography variant="body2">{str}</Typography>
                            </li>
                        )
                    }</ul>
                </Grid>
            </Grid>

            {/* File Limitations */}
            <Grid container item xs>
                <Grid item xs={12}>
                    <Typography variant="body1">File upload limitations :</Typography>
                </Grid>
                    <ul>{
                        fileLimitations.map((str, idx) =>
                            <li key={idx} style={{margin: 5}}>
                                <Typography variant="body2">{str}</Typography>
                            </li>
                        )
                    }</ul>
            </Grid>
        </Grid>
    )
}