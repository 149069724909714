import React from 'react';
import { Grid } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptcha({ formik, recaptchaRef }) {
    // Handles verifing the user response key given
    // when using a recaptcha
    async function verifyRecaptcha(key) {
        // Validating the response key server side
        const response = await fetch(
            window.REACT_APP_API_SERVER_ADDRESS + `/api/recaptcha/${encodeURIComponent(key)}`
        );
        const res = await response.json();

        // If valid then mark as verified
        if( res.success ) formik.setFieldValue("recaptcha", "verified");
    }

    return (
        <Grid container item justifyContent="center" style={{margin: "20px 0px 0px 0px"}}>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lf63d4aAAAAAAa9t0UQZYvk-fAui2Wog4xCnP4-"
                onChange={(key) => verifyRecaptcha(key)}
                onExpired={() => formik.setFieldValue("recaptcha", '')}
            />
        </Grid>
    )
}
