import React, { createContext, useState, useEffect } from 'react';

const DownloadsContext = createContext();

const DownloadsContextProvider = ({ children }) => {
    const [downloads, setDownloads] = useState();

    useEffect(() => {
        // Fetches the latest download links from the API-Server
        async function fetchLatestDownloadLinks() {
            const params = `/api/getLatestLinksForAllPlatforms`;
            const iTypes = await fetch(window.REACT_APP_API_SERVER_ADDRESS + params);
            return await iTypes.json();
        }

        fetchLatestDownloadLinks().then(links => {
            setDownloads(links);
        })
    },[])

    return (
        <DownloadsContext.Provider value={downloads}>
            {children}
        </DownloadsContext.Provider>
    );
};

export { DownloadsContext, DownloadsContextProvider }