import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LRAuthProvider } from 'loginradius-react';
import { UserContextProvider } from './contexts/UserContext';
import { MaintenanceModeContextProvider } from './contexts/MaintenanceModeContext';

ReactDOM.render(
    // <React.StrictMode>
        <MaintenanceModeContextProvider>
            <LRAuthProvider
                appName="etcconnect"
                apiKey="a5c428d2-de6f-47b5-ba7e-5f7534c54d80"
                redirectUri={window.location.origin}
                customDomain="login.etcconnect.com"
            >
                <UserContextProvider>
                    <App />
                </UserContextProvider>
            </LRAuthProvider>
        </MaintenanceModeContextProvider>,
    // </React.StrictMode>,
    document.getElementById("root")
);
serviceWorker.unregister();