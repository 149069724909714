import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import TileImage from './TileImage';

const useStyles = makeStyles((theme) => ({
    tile: {
        display: "flex",
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
        padding: 10,
        margin: 10,
        maxWidth: theme.palette.browse.tile.width,
        minWidth: theme.palette.browse.tile.width,
        "&:hover": {
            cursor: "pointer"
        }
    },
    info: {
        textAlign: "left",
        padding: theme.palette.browse.tile.infoSpacing,
        fontSize: theme.palette.browse.tile.requestInfo
    },
    ratings: {
        padding: "10px 0px 0px 0px",
    }
}));

// The content of the request tile based on its parent
const TileContent = ({ request, parent }) => {
    const styles = useStyles();
    if( parent === "account" ){
        return (
            <Grid item xs zeroMinWidth>
                <Typography noWrap variant="body1" classes={{root: styles.info}}>
                    <strong>{request.platform}</strong>
                </Typography>
                <Typography noWrap variant="body1" classes={{root: styles.info}}>
                    {request.product}
                </Typography>
                <Typography noWrap variant="body2" classes={{root: styles.info}}>
                    {request.mode + " mode"}
                </Typography>
                <Typography noWrap variant="body2" classes={{root: styles.info}}>
                    {request.status.name}
                </Typography>
            </Grid>
        )
    }
    if( parent === "browse" ){
        return (
            <Grid item xs zeroMinWidth>
                <Typography noWrap variant="body1" classes={{root: styles.info}}>
                    {request.mode + " mode"}
                </Typography>
                <Typography noWrap variant="body2" classes={{root: styles.info}}>
                    {"DMX Footprint: " + request.footprint}
                </Typography>
                <Typography noWrap variant="body2" classes={{root: styles.info}}>
                    {"Status: " + request.status.name}
                </Typography>
            </Grid>
        )
    }
}

export default function RequestTile({ requests, setTileSelected, parent, loading }) {
    const styles = useStyles();
    return (
        <Grid container direction="row">
            {
                requests.map((request, idx) => {
                    return (
                        !loading ?
                            <Grid key={idx} item>
                                <Box 
                                    borderRadius={0} 
                                    padding={2}
                                    onClick={() => setTileSelected(request)}
                                    classes={{root: styles.tile}}
                                >
                                    <Grid container>
                                        <Grid container direction="row" alignItems="center">
                                            {/* Image */}
                                            <TileImage icon="request" />

                                            {/* Content */}
                                            <TileContent parent={parent} request={request}/>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        :
                            <Grid key={idx} item>
                                <Skeleton variant='rect' height="64px" width="215px" style={{padding: 10, margin: 10}}/>
                            </Grid>
                    )
                })
            }
        </Grid>
    )
}