import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from "@material-ui/core";
import { Back, DblBack } from '../../../icons/icons';

const useStyles = makeStyles((theme) => ({
    tileHeaderProduct: {
        fontSize: theme.palette.browse.tile.headerProduct,
        marginLeft: 5
    },
    tileHeaderManufacturer: {
        fontSize: theme.palette.browse.tile.headerManufacturer,
    },
    left: {
        width: "7%",
        maxWidth: "45px"
    },
    right: {
        width: "93%",
    }
}));

export default function TilePaneBreadCrumb({ handleBack, tileSelected, tileProduct }) {
    const styles = useStyles();
    return (
        <Grid container alignItems="center" style={{padding: "10px 0 10px 16px"}}>
            <Grid item className={styles.left}>
                <IconButton aria-label="back" onClick={() => handleBack()} 
                    style={{padding: "10px 10px 10px 10px"}}
                >
                    {
                        tileSelected ? <DblBack /> : <Back />
                    }
                </IconButton>
            </Grid>
            <Grid container item alignItems='center' className={styles.right}>
                <Typography variant="h4" className={styles.tileHeaderProduct} display="inline" noWrap>
                    {
                        tileSelected ?
                            tileProduct.product + " : " + tileSelected.mode + " mode "
                        :
                            tileProduct.product
                    }
                    <span className={styles.tileHeaderManufacturer}> by {tileProduct.manufacturer}</span>
                </Typography>
            </Grid>
        </Grid>
    )
}
