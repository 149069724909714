import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Manufacturer from './Manufacturer';
import HubTextField from '../common/HubTextField';
import { PlatformSettings } from '../../AppSettings';

export default function FixtureSection({ formik, setSection, requestData }) {
    const [disableFixName, setDisableFixName] = React.useState(false);
    const [hideMode, setHideMode] = React.useState(false);

    // Hook used to set the Manufacturer dropdown to disabled.
    React.useEffect(() => {
        if( requestData ) {
            // console.log(requestData)
            if(requestData.disable === 'product' || requestData.disable === 'both') setDisableFixName(true);
        }
        // eslint-disable-next-line
    }, [requestData]);

    // This hook is used to hide the mode field when the hide_mode option is specified in the platforms config JSON.
    React.useEffect(() => {

        // Hides the mode field
        if('hide_mode' in PlatformSettings[formik.values.platform].request) {
            setHideMode(PlatformSettings[formik.values.platform].request.hide_mode);
            formik.setFieldValue("mode", "All")
        } else {
            setHideMode(false);
        }

        // eslint-disable-next-line
    }, [formik.values.platform]);

    return (
        <Grid container item xs direction="column">
            <Typography variant="body1" style={{margin: "20px 0px 10px 0px"}}><strong>Fixture Information</strong></Typography>

            {/* Manufacturer */}
            <Grid container item xs>
                <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                    <Typography variant="body1">Manufacturer</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Manufacturer formik={formik} setSection={setSection} requestData={requestData}/>
                </Grid>
            </Grid>

            {/* Other Manufacturer */}
            {
                formik.values.manufacturer !== "Other" ? null :
                    <Grid container item xs style={{marginTop: 10}}>
                        <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                            <Typography variant="body1">Other Manufacturer</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <HubTextField
                                fullWidth
                                variant="outlined"
                                value={formik.values.other}
                                name="other"
                                size={"small"}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    formik.setFieldTouched('other')
                                }}
                                onFocus={() => setSection("other")}
                                onBlur={() => setSection(null)}
                                error={formik.touched.other && Boolean(formik.errors.other)}
                                helperText={formik.touched.other ? formik.errors.other : ""}
                            />
                        </Grid>
                    </Grid>
            }

            {/* Fixture Name */}
            <Grid container item xs style={{marginTop: 10}}>
                <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                    <Typography variant="body1">Fixture Name</Typography>
                </Grid>
                <Grid item xs={6}>
                    <HubTextField
                        fullWidth
                        variant="outlined"
                        size={"small"}
                        disabled={disableFixName}
                        value={formik.values["fixtureName"]}
                        name={"fixtureName"}
                        onChange={(e) => {
                            formik.handleChange(e)
                            formik.setFieldTouched("fixtureName")
                        }}
                        onFocus={() => setSection("fixtureName")}
                        onMouseOver={() => setSection("fixtureName")}
                        onBlur={() => setSection(null)}
                        error={formik.touched["fixtureName"] && Boolean(formik.errors["fixtureName"])}
                        helperText={formik.touched["fixtureName"] ? formik.errors["fixtureName"] : ""}
                    />
                </Grid>
            </Grid>

            {/* Mode */}
            {
                hideMode ? null : 
                    <Grid container item xs style={{marginTop: 10}}>
                        <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                            <Typography variant="body1">Personality / Mode</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <HubTextField
                                fullWidth
                                variant="outlined"
                                size={"small"}
                                value={formik.values["mode"]}
                                name={"mode"}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    formik.setFieldTouched("mode")
                                }}
                                onFocus={() => setSection("mode")}
                                onMouseOver={() => setSection("mode")}
                                onBlur={() => setSection(null)}
                                error={formik.touched["mode"] && Boolean(formik.errors["mode"])}
                                helperText={formik.touched["mode"] ? formik.errors["mode"] : ""}
                            />
                        </Grid>
                    </Grid>
            }

            {/* Footprint */}
            <Grid container item xs style={{marginTop: 10}}>
                <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                    <Typography variant="body1">DMX Footprint</Typography>
                </Grid>
                <Grid item xs={6}>
                    <HubTextField
                        fullWidth
                        variant="outlined"
                        size={"small"}
                        value={formik.values["footprint"]}
                        name={"footprint"}
                        onChange={(e) => {
                            formik.handleChange(e)
                            formik.setFieldTouched("footprint")
                        }}
                        onFocus={() => setSection("footprint")}
                        onMouseOver={() => setSection("footprint")}
                        onBlur={() => setSection(null)}
                        error={formik.touched["footprint"] && Boolean(formik.errors["footprint"])}
                        helperText={formik.touched["footprint"] ? formik.errors["footprint"] : ""}
                    />
                </Grid>
            </Grid>
                    
        </Grid>
    )
}
