import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Divider } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import theme from '../../../../AppTheme';
import { BlueCheck } from '../../../../icons/icons';

const useStyles = makeStyles((theme) => ({
    label: {
        paddingLeft: 10
    },
    value: {
        paddingRight: 10
    },
    divide: {
        margin: "5px 1px 5px 1px",
        backgroundColor: theme.palette.etc.main
    }
}));

const Divide = () => {
    const styles = useStyles();
    return (
    <Grid item xs>
        <Divider variant="fullWidth" className={styles.divide}/>
    </Grid>
)}

// Left Side
const Revision = ({revision}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={8} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Revision</strong></Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" className={styles.value}>
            <Typography>{revision}</Typography>
        </Grid>
    </Grid>
)}

const UploadDate = ({history}) => {
    const styles = useStyles();
    const [date, setDate] = useState();

    useEffect(() => {
        // The date is from the most recent histor change
        if( history instanceof Array ) {
            // Reduce the dates
            const dat = history.reduce((r, c) => {
                if( new Date(r.date) > new Date(c.date) ) return r;
                else return c;
            });

            const dateObj = new Date(dat.date)
            const dateString = dateObj.getUTCFullYear() +"/"+ (dateObj.getUTCMonth()+1) +"/"+ dateObj.getUTCDate()
            setDate(dateString);
        }
    }, [history])

    return (
    <Grid container direction="row">
        <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Upload Date</strong></Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
            <Typography>{date}</Typography>
        </Grid>
    </Grid>
)}

// Right Side
const Verified = ({verified}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={8} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>HES Verified</strong></Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" className={styles.value}>
            <BlueCheck style={{color: verified ? theme.palette.etc.main : grey[400]}}/>
        </Grid>
    </Grid>
)}

const PreviewPkg = ({mode}) => {
    const styles = useStyles();
    const [previews, setPreviews] = React.useState(false)

    React.useEffect(() => {
        if( mode.hasOwnProperty('parts') ) {
            setPreviews(mode.parts[0].mode.previews)
        } else {
            setPreviews(mode.previews)
        }

    }, [mode])

    return (
    <Grid container direction="row">
        <Grid container item xs={8} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Preview Package</strong></Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" className={styles.value}>
            <BlueCheck style={{color: previews ? theme.palette.etc.main : grey[400]}}/>
        </Grid>
    </Grid>
)}

const Calibration = ({calibrated}) => {
    const styles = useStyles();

    return (
    <Grid container direction="row">
        <Grid container item xs={8} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Colour Calibration</strong></Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" className={styles.value}>
            <BlueCheck style={{color: calibrated ? theme.palette.etc.main : grey[400]}}/>
        </Grid>
    </Grid>
)}

export default function HogModeInfo({mode}) {
    return (
        <Grid container direction="row" spacing={2}>
            {/* Left */}
            <Grid item xs={6}>
                <Box
                    borderRadius={theme.palette.browse.reportCard.borderRadius}
                    style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                    padding={2}
                >
                    <Grid container>
                        <Revision revision={mode.revision}/>
                        <Divide/>
                        <UploadDate history={mode.history}/>
                    </Grid>
                </Box>
            </Grid>
            {/* Right */}
            <Grid item xs={6}>
                <Box
                    borderRadius={theme.palette.browse.reportCard.borderRadius}
                    style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                    padding={2}
                >
                    <Grid container>
                        <Verified verified={true}/>
                        <Divide/>
                        <PreviewPkg mode={mode}/>
                        {
                            mode.schema !== 'hogv5' ? null :
                                <>
                                    <Divide/>
                                    <Calibration calibrated={mode.calibrated}/>
                                </>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
