import React from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import HubTextField from '../common/HubTextField';
import HubDatePicker from './HubDatePicker';

export default function CommentsDelivery({ formik, setSection }) {
    return (
        <Grid container item xs direction="column">
            <Typography variant="body1" style={{padding: "20px 0px 10px 0px"}}>
                <strong>Comments and Delivery</strong>
            </Typography>

            {/* Privacy */}
            <Grid container>
                <Grid container item xs={4} justifyContent="flex-end" alignContent='center' style={{paddingRight: "15px"}}>
                    <Typography variant="body1">Private</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Checkbox
                        style={{padding: 0, margin: "10px 0 10px 0"}}
                        checked={formik.values.private}
                        onChange={(e) => formik.setFieldValue("private", e.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onMouseOver={() => setSection("private")}
                    />
                </Grid>
            </Grid>

            {/* Need By Date */}
            <HubDatePicker formik={formik} setSection={setSection} />

            {/* Comments */}
            <Grid container item xs style={{paddingTop: 10}}>
                <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                    <Typography variant="body1">Comment</Typography>
                </Grid>
                <Grid item xs={6}>
                    <HubTextField
                        fullWidth
                        multiline
                        minRows={4}
                        variant="outlined"
                        value={formik.values.comment}
                        onChange={(e) => formik.setFieldValue("comment", e.target.value)}
                        onMouseOver={() => setSection("comment")}
                        onFocus={() => setSection("comment")}
                        onBlur={() => setSection(null)}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}