import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Box, Divider } from '@material-ui/core';
import { DownloadsContext } from '../../contexts/DownloadsContext';
import { PlatformSettings } from '../../AppSettings';

const useStyles = makeStyles((theme) => ({
    ulink: {
        columnCount: 2,
        padding: "20px 0px 20px 35px"
    },
    link: {
        padding: "2px 0px 2px 0px"
    }
}));

export default function Description({platform, download}) {
    const styles = useStyles();
    const downloadCtx = useContext(DownloadsContext);
    const [downloadInfo, setDownloadInfo] = useState();

    // Hook used to set the downloadInfo from the Download Context
    useEffect(() => {
        //Set the download context bases on the platform setting browse download context prop
        let ctx = downloadCtx.filter(p => p.platform === PlatformSettings[platform].browse.downloadCtx);

        // Special Case for Eos and its versions
        if( platform === "Eos v2" || platform === "Eos v3") {
            // Split the Eos platform string to determine the version
            const split = platform.split(" ");
        
            // Set the download context for the specfied versio of Eos
            ctx = downloadCtx.filter(p => p.platform === "Eos")
            .map(info => {return{platform: "Eos", ...info[split[1]]}});
        }

        setDownloadInfo(ctx[0])
    },[downloadCtx, platform])

    return (
        <div>
            <Typography variant='body1'>
                {PlatformSettings[platform].browse.description.header}
            </Typography>
            <ul className={styles.ulink}>
                {
                    PlatformSettings[platform].supported_models.map((p, idx) => (
                        <li key={idx} className={styles.link}>
                            <Typography variant='body1'>{p}</Typography>
                        </li>
                    ))
                }
            </ul>
            <Typography variant='body1' gutterBottom>
                {PlatformSettings[platform].browse.description.help}
            </Typography>

            <br></br>
            {
                downloadInfo && downloadInfo ?
                    <>
                        <Box textAlign={'center'}>
                            <Typography variant='body1'>
                                Current version available for download: <strong>{downloadInfo.version}</strong>
                            </Typography>
                        </Box>
                        <Typography variant='body1'>
                            <br></br>
                            <strong>To download the latest full fixture library for this platform 
                                <Link 
                                    onClick={() => download(downloadInfo)} 
                                    href={downloadInfo.url}
                                    variant='body1' 
                                    style={{color: 'blue', cursor: 'pointer'}}
                                > click here.</Link>
                            </strong>
                        </Typography>
                    </>
                : null
            }
            <br></br>
            <Typography variant='body1'>
                <b>{PlatformSettings[platform].browse.description.notes}</b>
            </Typography>

            {
                platform === "Eos v3" ?
                    <>
                        <Typography variant='subtitle1' gutterBottom>
                            <strong style={{color: "red"}}>
                                Library installers are not backwards-compatible. Please ensure you download the correct version library to match 
                                your installed software. All library versions can be found here: 
                            </strong>
                                <a style={{marginLeft: "4px"}} href='https://www.etcconnect.com/Products/Consoles/Eos-Consoles/All-Eos-Downloads/Libraries.aspx' target="_blank" rel="noreferrer">Eos Family Libraries</a>.
                        </Typography>

                        <Typography variant='body1' gutterBottom style={{marginTop: "15px"}}>
                            For instructions on installing a new fixture library for Eos, see the Software Update section of the <a href='https://www.etcconnect.com/WebDocs/Controls/EosFamilyOnlineHelp/en-us/Default.htm#31_Eos_Configuration_Utility/Settings_General.htm?TocPath=Eos%2520Configuration%2520Utility%257C_____3' target="_blank" rel="noreferrer">Eos Family Manual</a>.
                        </Typography>
                        
                        <Divider variant='middle' style={{margin: 30}}/>
                        
                        {/* <br></br> */}
                        <Typography variant='body1' gutterBottom>
                            <strong>As of September 21, 2023, ETC is no longer able to build regular fixture libraries 
                                that natively install into Eos v2.9.x. However users can merge fixture profiles that are 
                                included in a modern library by exporting them from Eos v3.x into their v2.9.x environment. 
                                For instructions on importing these fixtures, see <a href='https://support.etcconnect.com/ETC/Consoles/Eos_Family/Software_and_Programming/Merging_Eos_v3x_Fixture_Profiles_into_v29x' target="_blank" rel="noreferrer">this support article</a>.</strong>
                        </Typography>
                    </>
                :
                    null    
            }
        </div>
    )
}
