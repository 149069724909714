import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog, DialogTitle, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    progress: {
        margin: 30,
        color: theme.palette.etc.main
    },
    footerTxt: {
        margin: 20,
        color: props => props.color,
        fontWeight: props => props.weight
    }
}));

export default function DownloadDialog({dlDialog, setDlDialog, selectedProduct, dlStatus}) {
    const statusProps = { color: dlStatus.color, fontWeight: dlStatus.weight };
    const styles = useStyles(statusProps);

    // Handles closing the download progress dialog and blocking it from
    // closing when the background is touched.
    function handleClose(evt, reason){
        // This will block any backdrop clicks from closing the dialog
        if(reason === 'backdropClick') return;

        // Close the download dialog
        setDlDialog(false);
    }
    
    return (
        selectedProduct && selectedProduct ?
            <div className={styles.root}>
                <Dialog onClose={handleClose} aria-labelledby="progress-dialog" open={dlDialog}>
                    <DialogTitle>
                        {`Generating the '${selectedProduct.product}' by
                        '${selectedProduct.manufacturer}' fixture library.`}
                    </DialogTitle>
                    <Grid container justifyContent="center">
                        <CircularProgress className={styles.progress} size={60}/>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Typography variant='body2' gutterBottom className={styles.footerTxt}>
                            {dlStatus.msg}
                        </Typography>
                    </Grid>
                </Dialog>
            </div>
        : null
    )
}
