// An object of all current platforms and what functionality they can perform on the site.
// preferable : Allows the platform to be displayed in the 'Preferred Platforms' list in the users account page.
// browsable : Platforms with this property set to true are available in the 'Platforms' list on the browse page.
// requestable : Plaforms with this property set to true are available in the 'Platforms' list on the request page.
// min_date : This is the minimum amount of timeframe (in days) needed preceeding the selected date in the 'Needs by Date' of the request page.
// due_date : This is the date which is set (in days). Cannont be less than the min_date

const CSET = {
    preferable: true,
    browsable: true,
    requestable: true,
    min_date: 21,
    due_date: null,
    supported_models: ["ColorSource 20 / 40", "ColorSource 20 / 40 AV", "EchoTouch", "Elaho Touch"],
    request: {
        additional_text: true,
        platform_info: {
            header: "This platform encompasses the following models: ",
            help: "For more specific instructions on how to install fixture profiles on the ColorSource / EchoTouch platform products please refer to the “Fixture Library” help topic in the ColorSource / EchoTouch help manual.",
            compatibility: "ColorSource Software v2.6 or greater is required."
        },
        hide_duedate: true,
        hide_mode: true
    },
    browse: {
        downloadCtx: "ColorSource / EchoTouch",
        description: {
            header: "The ColorSource platform encompasses the following products running ColorSource Software v2.6 or greater:",
            help: "For more specific instructions on how to install fixture profiles on the ColorSource / EchoTouch platform products please refer to the “Fixture Library” help topic in the ColorSourc / EchoTouch help manual.",
            notes: ""
        }
    }
}

module.exports = { CSET }