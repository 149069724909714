import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HubTextField from '../common/HubTextField';
import { getUsersPrimaryEmailAddress } from '../../config/helperFuns';
import { UserContext } from '../../contexts/UserContext';

export default function CustomerSection({ formik, setSection }) {
    const userCtx = React.useContext(UserContext);

    // Hook used to filter authenticated users name/email addresses from the user context
    React.useEffect(() => {
        if(userCtx){
            if(userCtx.isAuthenticated){
                formik.setFieldValue("email", getUsersPrimaryEmailAddress(userCtx.user.Email), true);            
                formik.setFieldValue("name", userCtx.user.FullName, true);
            }
        }
        // wants formik
        // eslint-disable-next-line
    }, [userCtx])

    return (
        userCtx && userCtx.isAuthenticated ? null :
            <Grid container item xs direction="column">
                <Typography variant="body1" style={{margin: "20px 0px 0px 0px"}}>
                    <strong>Customer Information</strong>
                </Typography>

                {
                    [{"var": "name", "displayName": "Name"},
                    {"var": "email", "displayName": "Email"}].map((data, idx) => {
                        return(
                            <Grid container item xs key={idx} style={{marginTop: 10}}>
                                <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                                    <Typography variant="body1">{data.displayName}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <HubTextField
                                        fullWidth
                                        // placeholder={data.displayName}
                                        variant="outlined"
                                        value={formik.values[data.var]}
                                        name={data.var}
                                        size={"small"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            formik.setFieldTouched(data.var)
                                        }}
                                        onFocus={() => setSection(data.var)}
                                        onMouseOver={() => setSection(data.var)}
                                        onBlur={() => setSection(null)}
                                        error={formik.touched[data.var] && Boolean(formik.errors[data.var])}
                                        helperText={formik.touched[data.var] ? formik.errors[data.var] : ""}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
    )
}
