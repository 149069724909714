const yup = require('yup');

// Common validation schema items used with all platforms
const CommonValidationSchema = yup.object({
    name: yup
        .string()
        .required('A customer name is required')
        .matches(/^[a-zA-Z0-9-_\s/]+$/, 'Invalid characters prohibited.')
        .min(2, 'At least (2) characters are needed'),
    email: yup
        .string()
        .required('An email address is required')
        .email("Invalid email format"),
    platform: yup
        .string()
        .required('A platform is required'),
    manufacturer: yup
        .string()
        .required('A manufacturer is required')
        .nullable(),
    other: yup
        .string()
        .matches(/^[a-zA-Z0-9-_\s]+$/, 'Invalid characters prohibited.')
        .min(2, 'At least (2) characters are needed'),
    fixtureName: yup
        .string()
        .required('A fixture name is required')
        .matches(/^[a-zA-Z0-9-_\s.,/+()]+$/, 'Invalid characters prohibited.')
        .min(2, 'At least (2) characters are needed'),
    mode: yup
        .string()
        .required('A fixture personality / mode is required')
        .matches(/^[a-zA-Z0-9-_\s.,/]+$/, 'Invalid characters prohibited.')
        .matches(/^(?!all|every)/i, 'Individual mode requests only.')
        .min(2, 'At least (2) characters are needed'),
    footprint: yup
        .number().positive().integer()
        .required('A DMX footprint required')
        .max(512, 'Acceptable values : 1 - 512'),
    documentation: yup
        .array()
        .required('A DMX protocol is required')
        .min(1, 'At least (1) document is needed')
        .max(3, 'Only 3 documents can be uploaded'),
    private: yup
        .boolean(),
    dueDate: yup
        .date(),
    comment: yup
        .string(),
    recaptcha: yup
        .string()
        .required()
});

// Used with (Eos, CSET, Cobalt) platforms
const NullDueDateSchema = yup.date().nullable()

// Used with platforms that do hide the mode field
const ModeAllSchema = yup
    .string()
    .required('A fixture personality / mode is required')
    .matches(/^[a-zA-Z0-9-_\s.,/]+$/, 'Invalid characters prohibited.')
    .min(2, 'At least (2) characters are needed')

// Validation used when 'Other' is selected as the manufacturer
const RequiredOtherManufactureSchema = yup
    .string()
    .matches(/^[a-zA-Z0-9-_\s]+$/, 'Invalid characters prohibited.')
    .test('is-valid-manufacturer', 'Custom is not a valid manufacturer name.', (value) => {
        if(value){
            const lowerValue = value.toLowerCase();
            if (
                lowerValue.includes('custom') ||
                lowerValue.includes('custm') || // You can add more variations here
                lowerValue.includes('kustom') // Add more variations as needed
            ) {
                return false;
            }
        }
        return true;
    })
    .test('is-valid-manufacturer', 'Other is not a valid manufacturer name.', (value) => {
        if(value){
            const lowerValue = value.toLowerCase();
            if (
                lowerValue.includes('other') // Add more variations as needed
            ) {
                return false;
            }
        }
        return true;
    })
    .min(2, 'At least (2) characters are needed')
    .required("A manufacturer name is required.")

// Validation used when the Other Manufacturer field is hidden
const OtherManufactureSchema = yup
    .string()
    .matches(/^[a-zA-Z0-9-_\s]+$/, 'Invalid characters prohibited.')
    .min(2, 'At least (2) characters are needed')

module.exports = { 
    CommonValidationSchema, 
    NullDueDateSchema, 
    RequiredOtherManufactureSchema, 
    OtherManufactureSchema, 
    ModeAllSchema 
}