import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from "@material-ui/core";
import CarallonModeInfo from "./sections/CarallonModeInfo";
import ProtocolInfo from "./sections/ProtocolInfo";
import RevisionInfo from "./sections/RevisionInfo";
import theme from '../../../AppTheme';

const useStyles = makeStyles((theme) => ({
    header: {
        margin: '0px 0px 15px 15px'
    },
    container: {
        padding: '0px 20px 0px 20px',
        maxHeight: theme.palette.browse.reportCard.height,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: theme.palette.etc.main,
            outline: '0px solid slategrey'
        },
    },
    table: {
        '& .MuiTableCell-root': {
            backgroundColor: theme.palette.browse.reportCard.backgroundColor,
            borderBottomColor: theme.palette.etc.main
        }
    }
}));

export default function HogReportCard({ mode }) {
    const styles = useStyles();
    // If selectedType then we show product report card. If not, then the loading card is shown.
    return (        
        <div className={styles.container}>
            <Grid container direction="column">
                {/* Info Section */}
                <Grid item xs style={{marginBottom: 10}}>
                    <CarallonModeInfo mode={mode}/>
                </Grid>

                {/* DMX Section */}
                <Grid item xs style={{marginBottom: 10}}>
                    <ProtocolInfo mode={mode}/>
                </Grid>

                {/* Revision/History Section */}
                <Grid item xs style={{marginBottom: 10}}>
                    <RevisionInfo mode={mode}/>
                </Grid>

                {/* User Notes Section */}
                {
                    mode.user_notes && mode.user_notes[0] !== "None" ?
                        <Box
                            borderRadius={theme.palette.browse.reportCard.borderRadius}
                            style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor, marginBottom: 10}}
                            padding={2}
                        >
                            <Grid container>
                                <TableContainer className={styles.container}>
                                    <Table size='medium' stickyHeader aria-label="User Notes" className={styles.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Grid container justifyContent="center">
                                                        <Typography variant="subtitle1">
                                                            <strong>User Notes</strong>
                                                        </Typography>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                mode.user_notes.map((note, idx) => (
                                                    <TableRow key={idx}>
                                                        <TableCell align="left">{note}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>
                    :
                        null
                }

                {/* Known Issues Section */}
                {
                    mode.known_issues && mode.known_issues[0] !== "None" ?
                        <Box
                            borderRadius={theme.palette.browse.reportCard.borderRadius}
                            style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                            padding={2}
                        >
                            <Grid container>
                                <TableContainer className={styles.container}>
                                    <Table size='medium' stickyHeader aria-label="User Notes" className={styles.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Grid container justifyContent="center">
                                                        <Typography variant="subtitle1">
                                                            <strong>Known Issues</strong>
                                                        </Typography>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                mode.known_issues.map((issue, idx) => (
                                                    <TableRow key={idx}>
                                                        <TableCell align="left">{issue}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>
                    :
                        null
                }

                {/* <Grid item xs>
                    <ModeActions auth={auth} mode={mode}/>
                </Grid> */}
            </Grid>
        </div>
    )
}