import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { getUsersPrimaryEmailAddress } from "../../config/helperFuns";
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
    errorHeader: {
        color: 'red'
    }
}));

export default function ConfirmationDialog({
    openDialog, 
    setOpenDialog, 
    confirmation, 
    formik, 
    init, 
    recaptchaRef,
    setReqDialogOpen
}) {
    const styles = useStyles();
    const userCtx = React.useContext(UserContext);

    const handleClose = (evt, reason) => {
        if(reason === "backdropClick") return;
        // Close the confirmation dialog
        setOpenDialog(false);
        // Close the request dialog
        setReqDialogOpen(false);
    };

    const handleNewRequest = () => {
        // Reset the form
        formik.resetForm(init);

        // If the user is authenticated then set their creds again
        if( userCtx.isAuthenticated ) {
            formik.setFieldValue("email", getUsersPrimaryEmailAddress(userCtx.user.Email), true);            
            formik.setFieldValue("name", userCtx.user.FullName, true);
        }

        // Reset the recaptcha
        recaptchaRef.current.props.grecaptcha.reset();

        // Close the confirmation dialog
        setOpenDialog(false);
        // Close the request dialog
        setReqDialogOpen(false);

        window.location.reload();
    }

    return (
        <Dialog
            onClose={handleClose}
            open={openDialog}
            style={{padding: 20}}
        >
        {
            confirmation.success ?
                <>  {/* Success */}
                    <DialogTitle id="confirmation-dialog">
                        Success!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Your request has successfully been submitted. You should receive an email shortly.
                            The ticket assigned to this request is {confirmation.issues.subTaskIssue.key}. Please refer to
                            this ticket when contacting support. Have a great day!
                        </DialogContentText>
                    </DialogContent>
                </>
            :
                <>  {/* Failed */}
                    <DialogTitle id="confirmation-dialog" className={styles.errorHeader}>
                        !! Error : {confirmation.message} !!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Failed to submit your request. Please try again or contact support for some help.
                        </DialogContentText>
                    </DialogContent>
                </>
        }
            <DialogActions style={{padding: 20}}>
                <Button variant="contained" color="secondary" onClick={handleNewRequest}>Done</Button>
            {/* {
                confirmation.platform === "Hog 4 v3" ?
                    <>
                        <Button variant="contained" color="secondary" onClick={handleNewRequest}>Make another request</Button>
                        <Button variant="contained" color="secondary" component={Link} to="/">Browse for profiles</Button>
                    </>
                :
                        <Button variant="contained" color="secondary" onClick={handleNewRequest}>Done</Button>
            } */}
            </DialogActions>
        </Dialog>
    );
}
