import React from "react";
import { Typography, Paper, Box } from "@material-ui/core";

const UserUploads = () => {
  return (
    <Paper style={{ margin: "0px 0px 10px 0px" }}>
      <Box p={2}>
        <Typography variant="h4">My Uploads</Typography>
      </Box>
    </Paper>
  )
}

export default UserUploads;