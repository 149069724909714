import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Divider } from "@material-ui/core";
import theme from '../../../../AppTheme';

const useStyles = makeStyles((theme) => ({
    label: {
        paddingLeft: 10
    },
    value: {
        paddingRight: 10
    },
    divide: {
        margin: "5px 1px 5px 1px",
        backgroundColor: theme.palette.etc.main
    }
}));

const Divid = () => {
    const styles = useStyles();
    return (
    <Grid item xs>
        <Divider variant="fullWidth" className={styles.divide}/>
    </Grid>
)}

// Left Side
const Revision = ({revision}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Revision</strong></Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
            <Typography noWrap>{revision}</Typography>
        </Grid>
    </Grid>
)}

const UploadDate = ({history}) => {
    const styles = useStyles();
    const [date, setDate] = useState();

    useEffect(() => {
        // The date is from the most recent histor change
        if( history instanceof Array ) {
            // Reduce the dates
            const dat = history.reduce((r, c) => {
                if( new Date(r.date) > new Date(c.date) ) return r;
                else return c;
            });

            const dateObj = new Date(dat.date)
            const dateString = dateObj.getUTCFullYear() +"/"+ (dateObj.getUTCMonth()+1) +"/"+ dateObj.getUTCDate()
            setDate(dateString);
        }
    }, [history])

    return (
    <Grid container direction="row">
        <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Upload Date</strong></Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
            <Typography noWrap>{date}</Typography>
        </Grid>
    </Grid>
)}

const AltName = ({name}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={5} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Alternate Name</strong></Typography>
        </Grid>
        <Grid container item xs={7} justifyContent="flex-end" className={styles.value}>
            <Typography noWrap>
            {   
                name !== "" ? name : "NA"
            }
            </Typography>
        </Grid>
    </Grid>
)}

const CurrentRelease = ({releases}) => {
    const styles = useStyles();
    return (
    <>
        <Divid/>
        <Grid container direction="row">
            <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
                <Typography><strong>Added Version</strong></Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
                <Typography noWrap>
                    {
                        releases.added_version === "" ? "NA" : releases.added_version
                    }
                </Typography>
            </Grid>
        </Grid>
        <Divid/>
        <Grid container direction="row">
            <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
                <Typography><strong>Current Version</strong></Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
                <Typography noWrap>
                    {
                        releases.current_version === "" ? "NA" : releases.current_version
                    }
                </Typography>
            </Grid>
        </Grid>
    </>
)}

// Right Side
const DmxStatus = ({status}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>DMX Status</strong></Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
            <Typography noWrap>{status}</Typography>
        </Grid>
    </Grid>
)}

const RdmStatus = ({status}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>RDM Status</strong></Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
            <Typography noWrap>{status}</Typography>
        </Grid>
    </Grid>
)}

const CmStatus = ({status}) => {
    const styles = useStyles();
    return (
    <Grid container direction="row">
        <Grid container item xs={6} justifyContent="flex-start" className={styles.label}>
            <Typography><strong>Colour Mix Status</strong></Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" className={styles.value}>
            <Typography noWrap>{status}</Typography>
        </Grid>
    </Grid>
)}

export default function CarallonModeInfo({mode}) {
    return (
        <Grid container direction="row" spacing={2}>
            {/* Left */}
            <Grid item xs={6}>
                <Box
                    borderRadius={theme.palette.browse.reportCard.borderRadius}
                    style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                    padding={2}
                >
                    <Grid container>
                        <Revision revision={mode.revision}/>
                        <Divid/>
                        <UploadDate history={mode.history}/>
                        <Divid/>
                        <AltName name={mode.alt_name}/>
                        {
                            mode.schema === "cset" || mode.schema === "cobalt" ?
                                <CurrentRelease releases={mode.current_release} />
                            :
                                null
                        }
                    </Grid>
                </Box>
            </Grid>
            {/* Right */}
            <Grid item xs={6}>
                <Box
                    borderRadius={theme.palette.browse.reportCard.borderRadius}
                    style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                    padding={2}
                >
                    <Grid container>
                        <DmxStatus status={mode.dmx_status}/>
                        <Divid/>
                        <RdmStatus status={mode.rdm_status}/>
                        <Divid/>
                        <CmStatus status={mode.cm_status}/>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
