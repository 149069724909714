import React, { createContext, useState, useEffect } from 'react';

const MaintenanceModeContext = createContext();

const MaintenanceModeContextProvider = ({ children }) => {
    const [maintenanceMode, setMaintenanceMode] = useState(false);

    useEffect(() => {
        // Fetches the maintenance mode status from the API-Server
        async function fetchMaintenanceMode() {
            const params = `/api/getMaintenanceMode`;
            const iTypes = await fetch(window.REACT_APP_API_SERVER_ADDRESS + params);
            return await iTypes.json();
        }

        // Initial fetch of maintenance mode status
        fetchMaintenanceMode().then(mMode => {
            setMaintenanceMode(mMode.maintenanceMode);
        })

        // Set interval to fetch maintenance mode every minute (60,000 milliseconds)
        const intervalId = setInterval(() => {
            fetchMaintenanceMode().then(mMode => {
                setMaintenanceMode(mMode.maintenanceMode);
            });
        }, 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    },[])

    return (
        <MaintenanceModeContext.Provider value={maintenanceMode}>
            {children}
        </MaintenanceModeContext.Provider>
    );
};

export { MaintenanceModeContext, MaintenanceModeContextProvider }