import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Navbar from "./components/navbar/Navbar";
import Footer from './components/footer/Footer';
import GlobalFonts from './fonts/fonts';
import Browse from "./components/browse/Browse";
import UploadPage from "./components/upload/UploadPage";
import Help from "./components/help/Help";
import theme from "./AppTheme";
import BreadCrumbBar from "./components/navbar/BreadCrumbBar";
import Account from "./components/account/Account";
import { PlatformsContextProvider } from "./contexts/PlatformsContext";
import { UserContext } from "./contexts/UserContext";
import { MaintenanceModeContext } from "./contexts/MaintenanceModeContext";
import Maintenance from "./Maintenance";

export default function App(){
    const userCtx = React.useContext(UserContext);
    const maintenanceModeCtx = React.useContext(MaintenanceModeContext);
    return (
        maintenanceModeCtx && !maintenanceModeCtx.maintenanceMode ?
            <Maintenance />
        :
            userCtx && userCtx.user ? // confirm the user context is loaded before loading anything
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <GlobalFonts />
                        <Container maxWidth={false} disableGutters>
                            <Navbar />
                            <BreadCrumbBar/>
                            <PlatformsContextProvider>
                                <Switch>
                                    <Route exact path="/" component={Browse} />
                                    <Route exact path="/browse" render={() => <Redirect to="/"/>} />
                                    <Route exact path="/request" render={() => <Redirect to="/"/>} />
                                    <Route exact path="/upload" component={UploadPage} />
                                    <Route exact path="/account" component={Account} />
                                    <Route exact path="/help" component={Help} />
                                </Switch>
                            </PlatformsContextProvider>
                            <Footer />
                        </Container>
                    </BrowserRouter>
                </ThemeProvider>
            :
                null
    );
};
