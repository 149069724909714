import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: "25px 0px 0px 25px"
    }
})

export default function Requesting(){
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container direction='column'>
                <Typography variant='h5' gutterBottom>
                    Requesting Profiles
                </Typography>
                <Typography gutterBottom variant='body1'>
                    Before requesting a fixture profile, please ensure that you are running the latest 
                    version of console software and the most up-to-date fixture library.
                </Typography>
                <Typography gutterBottom variant='body1'>
                    If you are in immediate need of a fixture profile, please refer to your console's help 
                    manual for instructions on how build fixture profiles using software on the console. EOS 
                    operators running software v3.2.4 or greater also have the option to do a
                    <strong>
                        <Link href='https://support.etcconnect.com/ETC/Consoles/Eos_Family/Software_and_Programming/GDTF' variant='body1' style={{color: 'blue', cursor: 'pointer'}}> GDTF Import.</Link>
                    </strong>
                </Typography>
                <Typography gutterBottom variant='body1'>
                    To request fixture profiles from the ETC Fixture Library Hub follow the steps below:
                    <ol style={{margin: "15px 40px"}}>
                        <li>
                            Begin by searching for the product you require a profile for by using the Fixture 
                            Library Hub's browse and filter tools on the Hub's main page.
                        </li>
                        <li>
                            If a product match occurs but an official profile for the mode you require is not found 
                            then click on the "create a request" option to begin a new fixture request for that product.
                        </li>
                        <li>
                            If a product match cannot be found based on the search and filter criteria, an option to 
                            create a new request will appear in the product list. Click on this option to start a new request.
                        </li>
                    </ol>
                </Typography>
                <Typography gutterBottom variant='body1'>
                    ETC recommends that you log in before submitting your request so that you can track your requests 
                    on the the Fixture Library Hub. You can submit fixture requests without logging in but the request 
                    form will require additional information such as your name and email address. This is so that we 
                    can communicate the status of your request and deliver the profile to you.
                </Typography>
                <br></br>
                <Typography gutterBottom variant="h6">
                    <strong>Important Note about Requesting Fixture Profiles for Life Threatening Devices</strong>
                </Typography>
                <Typography gutterBottom variant='body1'>
                    ETC policy is that we do not create/provide fixture definitions for our controls products (entertainment/architectural) 
                    for devices that would be classified as pyrotechnics or could be dangerous to show personnel/audience members (where 
                    best practices require life-safety overrides such as Emergency Stops, for example). <strong>This also includes Class 3B 
                    or Class 4 laser products and laser products without clear Class markings and Rigging Devices such as winches.</strong>
                </Typography>
                <Typography gutterBottom variant='body1'>
                    While the boundary of what might be considered dangerous may be fuzzy, ETC will always err on the side of safety in these cases.
                </Typography>
                <Typography gutterBottom variant='body1'>
                    There is nothing that stops a user from creating their own profile or using one of our external triggering methods to 
                    interface with pyrotechnics/special effects or similar devices. ETC will not provide pre-built profiles for items that 
                    could be considered as such.
                </Typography>
                <Typography gutterBottom variant='body1'>
                    <strong>Product families affected:</strong> ETC Eos, ETC Cobalt, ETC ColorSource, ETC SmartFade, HES Hog 4, ETC Paradigm, 
                    ETC Echo, ETC Mosaic
                </Typography>
                <Typography gutterBottom variant='body1' style={{marginTop: 30}}>
                    For more information about laser safety in your global region click the appropriate link below.
                    <ul style={{margin: "15px 50px"}}>
                        <li>
                            <strong>
                                <Link href='https://www.fda.gov/radiation-emitting-products/home-business-and-entertainment-products/laser-products-and-instruments' variant='body1' style={{color: 'blue', cursor: 'pointer'}}>USA</Link>
                            </strong>
                        </li>
                        <li>
                            <strong>
                                <Link href='https://www.gov.uk/government/publications/laser-radiation-safety-advice/laser-radiation-safety-advice' variant='body1' style={{color: 'blue', cursor: 'pointer'}}>UK</Link>
                            </strong>
                        </li>
                    </ul>
                </Typography>
            </Grid>
        </div>
    )
}