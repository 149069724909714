import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
        // padding: "5px",
        padding: "5px 0 5px 0"
        // marginLeft: 10
    }
}));

const BreadcrumbBar = props => {
    const styles = useStyles();
    const { history, location } = props;
    const { pathname } = location;

    // Get array of path parts and filters out empty string
    const pathnames = pathname.split('/').filter(x => x);

    const pathLookups = {
        "": "",
        // "browse": "Browse",
        // "request": "Request Form",
        "account": "Account",
        // "upload": "Upload",
        "help": "Help",
    }

    return (
        <Grid container justifyContent="center" className={styles.root}>
            <Grid item xs={9}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link color="textPrimary" onClick={() => history.push("/")} style={{display: "block", marginLeft: 15}}>
                        <Typography variant="h6">Fixture Library Hub</Typography>
                    </Link>
                    {
                        pathnames.map((name, index) => {
                            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`
                            // checks if breadcumb is last
                            const isLast = index === pathnames.length - 1
                            return isLast ? (
                                <Typography key={index}>{pathLookups[name]}</Typography>
                            ) : (
                                <Link onClick={() => history.push(routeTo)} key={index}>{pathLookups[name]}</Link>
                            )
                        })
                    }
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
}
export default withRouter(BreadcrumbBar);