import React, {useContext} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Products from "./Products";
import { Grid, Paper, Box } from "@material-ui/core";
import ManufacturerSearchBar from "./ManufacturerSearchBar";
import ReportCardLoader from "./report-cards/ReportCardLoader";
import ProductSearchBar from "./ProductSearchBar";
import TilePane from "./tile-pane/TilePane";
import PlatformSelector from "./PlatformSelector";
import { Skeleton } from '@material-ui/lab';
import BrowseLanding from "./BrowseLanding";
import DownloadDialog from "./DownloadDialog";
import { getSchemaForPlatform, getPlatformObject } from '../../config/helperFuns';
import TilePaneBreadCrumb from "./tile-pane/TilePaneBreadCrumb";
import { PlatformsContext } from '../../contexts/PlatformsContext';
import { UserContext } from "../../contexts/UserContext";
import { DownloadsContextProvider, DownloadsContext } from '../../contexts/DownloadsContext';
import RequestDialog from "../request/RequestDialog";
import { v4 as uuidv4 } from "uuid"; 

// Default text displayed under the download progress indicator
const defaultDownloadStatus = "Once the library has been generated the download will automatially begin."

// Key values of available "random" colors
// Random_Color:
const colors = [
    {"red": "whitesmoke"},
    {"#ffe0b3": "#262626"},
    {"yellow": "#262626"},
    {"olive": "whitesmoke"},
    {"green": "#262626"},
    {"teal": "#262626"},
    {"blue": "#262626"},
    {"violet": "#262626"},
    {"purple": "#262626"},
    {"pink": "#262626"},
    {"#e25061": "#262626"},
    {"#b41825": "whitesmoke"},
    {"#87121c": "whitesmoke"},
    {"#650d15": "whitesmoke"},
    {"#4c0a10": "whitesmoke"},
    {"#e89862": "#262626"},
    {"#c6712b": "#262626"},
    {"#955520": "whitesmoke"},
    {"#704018": "whitesmoke"},
    {"#543012": "whitesmoke"},
    {"#8cd65c": "#262626"},
    {"#72bc42": "#262626"},
    {"#58853f": "#262626"},
    {"#42642f": "whitesmoke"},
    {"#55c6a5": "#262626"},
    {"#129877": "#262626"},
    {"#0d7239": "whitesmoke"},
    {"#074032": "whitesmoke"},
    {"#4793c6": "#262626"},
    {"#1e5492": "whitesmoke"},
    {"#163f6e": "whitesmoke"},
    {"#102f52": "whitesmoke"},
    {"#0c233d": "whitesmoke"},
    {"#694bc6": "whitesmoke"},
    {"#4e1ec4": "whitesmoke"},
    {"#31127c": "whitesmoke"},
    {"#772677": "whitesmoke"},
    {"#4a024c": "whitesmoke"}
];
const hesColor = {"#fcd20a": "black"};
const etcColor = {"#00aeef": "black"};

// returns a random color from the colors array above
const randomColor = () => {
    const min = Math.ceil(0);
    const max = Math.floor(colors.length - 1);
    return colors[Math.floor(Math.random() * (max - min + 1)) + min];
};

// The number below is when the regex begins to start
const charsTillTrigger = 2;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0px 15px 0px 15px"
    },
    left: {
        width: "22%",
        minWidth: "230px"
    },
    right: {
        width: "53%"
    },
    paper: {
        backgroundColor: theme.palette.background.main, 
        color: "white",
        borderRadius: "0px"
    }
}));

// export default function Browse(){
function Browse(){
    const styles = useStyles();
    const platformCtx = useContext(PlatformsContext);
    const userCtx = useContext(UserContext);
    const downloadsCtx = useContext(DownloadsContext);

    // Static State
    const [manufacturers, setManufacturers] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [updateProducts, setUpdateProducts] = React.useState(false);

    // Platform Selector State
    const [platform, setPlatform] = React.useState(userCtx.preferredPlatform);

    // Manufacturer Search Bar State
    const [selectedManufacturers, setSelectedManufacturers] = React.useState([]);
    const [disableManufacturersField, setDisableManufacturersField] = React.useState(true);

    // Products Search Bar State
    const [productSearch, setProductSearch] = React.useState("");
    const [disableProductSearchField, setDisableProductSearchField] = React.useState(true);

    // Products List State
    const [visableProducts, setVisableProducts] = React.useState([]);
    const [updateVisableProducts, setUpdateVisableProducts] = React.useState(false);
    const [activeProduct, setActiveProduct] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState();
    const [displayByManufacturerInList, setDisplayByManufacturerInList] = React.useState(true);
    const [disableProductsList, setDisableProductsList] = React.useState(true);

    // Tile Pane State
    const [tilePaneLoading, setTilePaneLoading] = React.useState(true);
    const [tileProfiles, setTileProfiles] = React.useState([]);
    const [tileCompounds, setTileCompounds] = React.useState([]);
    const [tileRequests, setTileRequests] = React.useState([]);
    const [tileProduct, setTileProduct] = React.useState(null);
    const [tileSelected, setTileSelected] = React.useState();
    const [downloadCtx, setDownloadCtx] = React.useState();

    // Filter State
    const [filterContext, setFilterContext] = React.useState(null);

    // Download Dialog
    const [dlDialog, setDlDialog] = React.useState(false);
    const [dlStatus, setDlStatus] = React.useState({color: "black", msg: defaultDownloadStatus, weight: "normal"});

    // Request Dialog
    const [reqDialogOpen, setReqDialogOpen] = React.useState(false);
    const [reqData, setReqData] = React.useState(null);


  // Hooks used to populate data
    // Hook to fetch the (/api/manufacturers/{platform}) manufacturers from the API Server when the platform changes
    React.useEffect(() => {
        // fetch the manufacturers
        async function fetchManufacturers() {
            const params = `/api/manufacturers?schema=${getSchemaForPlatform(platform, platformCtx)}&product_count=true`;
            const manRtn = await fetch(window.REACT_APP_API_SERVER_ADDRESS + params);
            const mData = await manRtn.json();

            // Remap the returned manufacturer list
            const manufacturerData = mData.map(m => {
                let color = randomColor();
                // hard code High End and ETC colors
                if (m.manufacturer === "High End" || m.manufacturer === "High End Systems") color = hesColor;
                if (m.manufacturer === "ETC") color = etcColor;
                
                return {
                    "title": m.manufacturer,
                    "productCount": parseInt(m.count),
                    color
                }
            });

            setManufacturers(manufacturerData);
            
            // Now update the products
            setUpdateProducts(true);
        };
        fetchManufacturers();
        
        // Display a skeleton in the list then update the products list
        setDisableProductsList(true);

        // Reset the manufacturers filter when the platform changes.
        setSelectedManufacturers([]);

        // This will reset the browse page back to the platform descriptions when the platform changes.
        setSelectedProduct("nothing");

        setReqData(null);
    }, [platform, platformCtx]);

    // Hook to fetch the (/api/products/{platform}) products from the API Server when updateProducts is true
    React.useEffect(() => {
        // fetch the products
        async function fetchProducts() {
            const params = `/api/products?schema=${getSchemaForPlatform(platform, platformCtx)}&platform=${getPlatformObject(platform, platformCtx).jiraSubTask}`;
            const response = await fetch(window.REACT_APP_API_SERVER_ADDRESS + params);
            const pData = await response.json();

            // Remap the product data adding : color, active
            let productData = pData.map( prod => {
                // set the color randomly
                let color = null;

                // Set the color for the product
                if (prod.manufacturer === "High End") color = hesColor;
                else if (prod.manufacturer === "ETC") color = etcColor;
                else {
                    // Get the color from the manufacturer data
                    for( let m of manufacturers ) {
                        if( m.title === prod.manufacturer ) {
                            color = m.color;
                            break;
                        }
                    }
                }

                prod.color = color;
                prod.active = false;
                return prod;
            });

            // Sorts the products list alphabetically
            productData.sort((a, b) => {
                var x = a.product.toLowerCase();
                var y = b.product.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
            });

            // Sets the static products state
            setProducts(productData);
            // Sets the visable products. These are what are seen in the products list            
            setVisableProducts(productData);

            // Now that the lists/fields are populated enable them
            setDisableProductsList(false);
            setDisableManufacturersField(false);
            setDisableProductSearchField(false);
        }

        if( updateProducts ) {
            // List of Platforms that do not have the browse info available yet so are request only
            const requestOnly = ['Mosaic', 'Paradigm v2', 'Paradigm v3', 'Paradigm v4', 'Paradigm v5']
            if(requestOnly.includes(platform)) {
                // Only list item is for starting a request.
                const pData = [{
                    manufacturer: null,
                    product: `Create a fixture request`,
                    request: "none",
                    platform,
                    manufacturers: manufacturers.map(m => m.title).sort()
                }]

                // Sets the static products state
                setProducts(pData);
                // Sets the visable products. These are what are seen in the products list            
                setVisableProducts(pData);

                // Now that the lists/fields are populated enable them
                setDisableProductsList(false);
                setDisableManufacturersField(true);
                setDisableProductSearchField(true);
                setUpdateProducts(false);
            } else {
                fetchProducts();
                setUpdateProducts(false);
            }
        }
    }, [updateProducts, platform, manufacturers, platformCtx]);


  // Hooks used for selection
    // Hook used to (/api/query) query the server for the selected modes/requests for the 
    // given manufacturer/project/schema
    React.useEffect(() => {
        // used to query postgres
        const query = async () => {
            const params = `/api/query?manufacturer=${encodeURIComponent(selectedProduct.manufacturer)}&product=${encodeURIComponent(selectedProduct.product)}&schema=${encodeURIComponent(selectedProduct.schema)}&platform=${encodeURIComponent(platform)}`;
            const response = await fetch(window.REACT_APP_API_SERVER_ADDRESS + params);
            return await response.json();
        };

        // if a product has been selected
        if (selectedProduct) {
            // If selected product = nothing then the back button was pushed and
            // the tile panes need to be reset back to the initial landing page
            if(selectedProduct === "nothing"){
                setTileProduct(null);
                setTileProfiles([]);
                setTileCompounds([]);
                setTileRequests([]);
                setTileSelected();
                setActiveProduct(true);
                return;
            }

            // If the selected product item is a start request button
            if(selectedProduct.request) {
                let requestData = {
                    manufacturer: selectedProduct.manufacturer,
                    product: "",
                    platform,
                    manufacturers: manufacturers.map(m => m.title).sort(),
                    disable: selectedProduct.request
                }
                
                if(selectedProduct.search) requestData.product = selectedProduct.search;
                setReqData(requestData);
                setReqDialogOpen(true);
                return;
            }
            
            // Handle when a stand alone request is selected in the products list
            if( selectedProduct.key ) {
                setTileProduct(selectedProduct);
                setTileProfiles([]);
                setTileCompounds([]);
                setTileRequests([selectedProduct]);
                setTileSelected();
                setTilePaneLoading(false);
                setActiveProduct(true);
                return;
            }

            // Enable the spinner over the right side
            setTilePaneLoading(true);

            // Mark the selected product as active in the list
            setActiveProduct(true);

            // Perform the query to the API Server
            query().then(query => {
                const man = query.manufacturer;
                const prod = query.product;

                if( !query.modes ) query.modes = [];
                if( !query.compounds ) query.compounds = [];

                // if there are compounds available for the query
                // then map the associated part and mode
                if( query.compounds.length > 0 ) {
                    // Loop over each compound fixture
                    for(const compound of query.compounds){
                        // Var used to calculate the total footprint for the compound fixture
                        let cmpDMXFootprint = 0
                        
                        // Map the associate compound part to its mode
                        compound.parts.map((part, idx) => {
                            const mappedMode = query.modes.filter(m => m.name.toLowerCase() === part.type.toLowerCase());
                            const prtMode = mappedMode[0];
                            cmpDMXFootprint += prtMode.footprint
                            part.mode = prtMode;
                            return part;
                        });
                        // Add the compound footprint to the compound fixture
                        compound.footprint = cmpDMXFootprint;
                    }
                }

                // Remap the query
                const profiles = query.modes.map(m => {
                    m.manufacturer = man;
                    m.product = prod;
                    return m;
                })

                setTileProduct(selectedProduct);
                setTileProfiles(profiles);
                setTileCompounds(query.compounds);
                setTileRequests(selectedProduct.requests);
                setTileSelected();
                setTilePaneLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [selectedProduct]);

    // Hook used to set the currently selected product active in the products list
    React.useEffect(() => {
        if(activeProduct) {
            // Mark the selected product as active
            visableProducts.map((p) => {
                if (p.uuid === selectedProduct.uuid) p.active = true;
                else p.active = false;
                return p;
            });
            setActiveProduct(false);
        }
    }, [selectedProduct, activeProduct, visableProducts]);
    
    
  // Filter Hooks
    // Hook handles filtering the products list
    React.useEffect(() => {
        if( updateVisableProducts ) {
            setVisableProducts( visProducts => {
                const { manufacturers, search } = filterContext;
                const regex = new RegExp(search, "i");
                let vProds = [];
                    
                // Filters based on both Manufacturer and Product fields
                if( search.length && manufacturers.length ) {
                    vProds = manufacturers.flatMap(m => {
                        const filteredProducts = products
                            .filter(p => p.manufacturer === m.title && regex.exec(p.product))
                            .map(p => ({
                                ...p,
                                style: {
                                    borderColor: Object.keys(m.color)[0],
                                    borderWidth: "6px",
                                    borderStyle: "none solid none solid",
                                },
                            }));
                        return [
                            {
                                active: false,
                                style: {
                                    borderColor: Object.keys(m.color)[0],
                                    borderWidth: "10px",
                                    borderStyle: "none solid none solid",
                                },
                                manufacturer: m.title,
                                product: `Create request for '${m.title}' : '${search}'`,
                                uuid: uuidv4(),
                                request: "both",
                                search
                            },
                            ...filteredProducts,
                        ];
                    });
                }

                // Filter based only on the Manufacturers field
                if( !search.length && manufacturers.length ) {
                    // Loop over each selected manufacturer and filter
                    // the products. Once the products are filtered then remap them to indicate
                    // their associated manufacturer with matching border. Once the products
                    // are filtered and remapped push them into the array with the spread operator.
                    vProds = manufacturers.flatMap(man => {
                        const productsForManufacturer = products.filter(product => product.manufacturer === man.title);
                        const firstProduct = productsForManufacturer[0]; // Get the first product for the color
                        return [
                            {
                                active: false,
                                style: {
                                    borderColor: firstProduct ? Object.keys(firstProduct.color)[0] : null,
                                    borderWidth: "10px",
                                    borderStyle: "none solid none solid",
                                },
                                manufacturer: man.title,
                                product: `Create request for '${man.title}'`,
                                uuid: uuidv4(),
                                request: "manufacturer"
                            },
                            ...productsForManufacturer.map(p => ({
                                ...p,
                                style: {
                                    borderColor: Object.keys(p.color)[0],
                                    borderWidth: "6px",
                                    borderStyle: "none solid none solid",
                                },
                            })),
                        ];
                    });

                    // If filtering by manufacturers which are color coded then there is no
                    // need to display the 'by manufacturer' in the products list
                    setDisplayByManufacturerInList(false);
                }

                // Filter based only on the Product field
                if( search.length > charsTillTrigger && !manufacturers.length ) {
                    const ps = products.filter(p => regex.test(p.product)).map((p) => {
                        delete p.style;
                        return p;
                    });
                    vProds = [{
                        active: false,
                        manufacturer: "Other",
                        product: `Start a new request for a '${search}'`,
                        search,
                        uuid: uuidv4(),
                        request: "product"
                    }, ...ps];
                }
    
                return vProds;
            })

            setUpdateVisableProducts(false);
        }
    }, [updateVisableProducts, filterContext, products])

    // Hook used to trigger product list updates via a filter context and resets
    React.useEffect(() => {
        if( filterContext && products.length > 0 ) {
            if( filterContext.manufacturers.length || filterContext.search.length > charsTillTrigger ) {
                setUpdateVisableProducts(true);
            }
    
            // Handle reset
            if( !filterContext.manufacturers.length && !filterContext.search.length ) {
                products.map((p) => {
                    delete p.style;
                    return p;
                });
                setVisableProducts(products);
            }
        }
    }, [filterContext, products])

    // Hook to update the filter context when filtering by manufacturers
    React.useEffect(() => {
        setFilterContext(ctx => {
            return ({
                ...ctx,
                manufacturers: selectedManufacturers
            })
        })
    }, [selectedManufacturers])

    // Hook to update the filter context when filtering by the wildcard product search
    React.useEffect(() => {
        setFilterContext(ctx => {
            return ({
                ...ctx,
                search: productSearch
            })
        })
    }, [productSearch])

    // Hook used to update the downloadCtx to the correct context based on the selected platform
    React.useEffect(() => {
        // Wait until the downloadsCtx is loaded
        if(downloadsCtx) {
            let downloadInfo = ""
            
            // RegEx to test the current platform against
            const carallonPlatformsPattern = new RegExp(/Eos v*/);
            if(carallonPlatformsPattern.test(platform)) {
                // Split the Eos download context to determine the library version
                const split = platform.split(" ");
                const eosPlatform = split[0];
                const eosVersion = split[1];
                // Filter the download context to the specific Eos version context
                downloadInfo = downloadsCtx.filter(ctx => ctx.platform === eosPlatform)
                .map(info => {return {platform: eosPlatform, ...info[eosVersion]}})[0];
            }

            // RegEx to test the current platform against
            const csetPlatformsPattern = new RegExp(/ColorSource/);
            if(csetPlatformsPattern.test(platform)) {
                // Filter the download context to the specific ColorSource / EchoTouch context
                downloadInfo = downloadsCtx.filter(ctx => ctx.platform === "ColorSource / EchoTouch")[0];
            }

            // RegEx to test the current platform against
            const cobaltPlatformsPattern = new RegExp(/Cobalt/);
            if(cobaltPlatformsPattern.test(platform)) {
                // Filter the download context to the specific Cobalt context
                downloadInfo = downloadsCtx.filter(ctx => ctx.platform === "Cobalt")[0];
            }

            // RegEx to test the current platform against
            const hogPlatformsPattern = new RegExp(/Hog v*/);
            if(hogPlatformsPattern.test(platform)) {
                // Filter the download context to the specific Hog context
                downloadInfo = downloadsCtx.filter(ctx => ctx.platform === "Hog")[0];
            }

            // Set the current download context
            setDownloadCtx(downloadInfo);
        }
    }, [platform, downloadsCtx])

    // Function used for initiating the Hog fixture library download
    async function handleDownload(){
        // Below here is for building Hog on-demand standalone lib
        setDlDialog(true);

        // fetch the products
        const params = `/api/download`;
        fetch(window.REACT_APP_API_SERVER_ADDRESS + params, {
            method: 'POST',
            body: JSON.stringify({
                "manufacturer": selectedProduct.manufacturer,
                "product": selectedProduct.product,
                "schema": selectedProduct.schema
            }),
            headers: {
                'Accept': 'application/octet-stream',
                'Content-Type': 'application/json'
            }
        }).then( async (res) => {
            if( res.status === 200 ) {

                // Get the library blob data from the response
                const blob = await res.blob();

                // Set the library extension based on the Hog schema
                let libExtension = "hog4lib";
                if ( selectedProduct.schema === "hogv5" ) libExtension = "hog5lib"

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${selectedProduct.manufacturer.replace(/\s/, "_")}-${selectedProduct.product.replace(/\s/, "_")}.${libExtension}`);
                // Append to html page
                document.body.appendChild(link);
                // Force download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDlDialog(false);
                return;
            }
            
            // Handle any errors
            const errJson = await res.json();
            
            // Update the progress dialogs status text
            setDlStatus({color: "red", msg: errJson.message, weight: "bold"});
            
            // Display the updated error for 5 seconds before closing the dialog
            setTimeout(() =>{
                setDlDialog(false);
            }, 5000)
        });
    }

    // Handles the single and double chevron back button on either the report card or tile pane
    function handleBack(){
        // If a tile is selected then we know that this back push (double chevron)
        // would be the step out of a report card
        if(tileSelected){
            setTileSelected();
            return;
        }

        // Handle the (single chevron) back from the tile pane
        setSelectedProduct("nothing");
    }

    return (
        <div className={styles.root}>
            <Grid container justifyContent="center">
                {/* Left`` */}
                <Grid item className={styles.left}>
                    <Paper className={styles.paper}>
                        <PlatformSelector
                            platform={platform}
                            setPlatform={setPlatform}
                        />

                        <ManufacturerSearchBar
                            manufacturers={manufacturers}
                            selectedManufacturers={selectedManufacturers}
                            setSelectedManufacturers={setSelectedManufacturers}
                            disabled={disableManufacturersField}
                            setDisplayByManufacturerInList={setDisplayByManufacturerInList}
                        />
                        
                        <ProductSearchBar 
                            productSearch={productSearch} 
                            setProductSearch={setProductSearch}
                            disabled={disableProductSearchField}
                        />
                        
                        <Products 
                            products={visableProducts} 
                            setSelectedProduct={setSelectedProduct}
                            displayByManufacturerInList={displayByManufacturerInList}
                            disabled={disableProductsList}
                            setReqDialogOpen={setReqDialogOpen}
                        />
                    </Paper>
                </Grid>
                {/* Right */}
                <Grid item className={styles.right}>
                    {
                        !tileProduct ?
                            <BrowseLanding platform={platform} />
                        :
                            <Box >
                                {/* Tile Pane Breadcrumbs */}
                                {
                                    !tilePaneLoading ?
                                        <TilePaneBreadCrumb 
                                            tileSelected={tileSelected}
                                            tileProduct={tileProduct}
                                            handleBack={handleBack}    
                                        />
                                    :
                                        <Box display="inline-flex" flexDirection="row" alignItems="center" style={{padding: "24px 0 10px 30px"}}>
                                            <Box mr={3}>
                                                <Skeleton variant="rect" width={20} height={20}/>
                                            </Box>
                                            <Box>
                                                <Skeleton variant="rect" height={30} width={500}/>
                                            </Box>
                                        </Box>
                                }
                                {/* Tile Pane */}
                                {
                                    !tileSelected ?
                                        <TilePane 
                                            loading={tilePaneLoading}
                                            profiles={tileProfiles}
                                            compounds={tileCompounds}
                                            requests={tileRequests}
                                            setTileSelected={setTileSelected}
                                            handleDownload={handleDownload}
                                            tileProduct={tileProduct}
                                            downloadCtx={downloadCtx}
                                            setReqDialogOpen={setReqDialogOpen}
                                            setReqData={setReqData}
                                            platform={platform}
                                            manufacturers={manufacturers}
                                        />
                                    :
                                        <ReportCardLoader 
                                            tileSelected={tileSelected} 
                                            schema={selectedProduct.schema}
                                        />
                                }
                            </Box>
                    }
                </Grid>
            </Grid>

            <DownloadDialog 
                dlDialog={dlDialog} 
                setDlDialog={setDlDialog} 
                selectedProduct={selectedProduct}
                dlStatus={dlStatus}
            />

            <RequestDialog 
                reqDialogOpen={reqDialogOpen} 
                setReqDialogOpen={setReqDialogOpen}
                requestData={reqData}
            />
        </div>
    );
};

// Hack to wait for the Platforms Context to load before using loading the page
export default function BrowseTillContextIsLoaded(){
    const platformCtx = useContext(PlatformsContext)
    return  platformCtx && platformCtx ? 
            <DownloadsContextProvider>
                <Browse /> 
            </DownloadsContextProvider>
        :   
            null
}