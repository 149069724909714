import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ModeInfo from './sections/HogModelnfo';
import RevisionInfo from './sections/RevisionInfo';
import CompoundPartInfo from './sections/CompoundPartInfo';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0px 20px 0px 20px',
        maxHeight: theme.palette.browse.reportCard.height,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: theme.palette.etc.main,
            outline: '0px solid slategrey'
        },
    }
}));

export default function CompoundReportCard({ compound }) {
    const styles = useStyles();
    return(
        <div className={styles.container}>
            <Grid container direction="column">
                {/* Info Section */}
                <Grid item xs style={{marginBottom: 10}}>
                    <ModeInfo mode={compound}/>
                </Grid>

                {/* Compound Part Info */}
                <Grid item xs style={{marginBottom: 10}}>
                    <CompoundPartInfo compound={compound}/>
                </Grid>

                {/* Revision/History Section */}
                <Grid item xs>
                    <RevisionInfo mode={compound}/>
                </Grid>
            </Grid>
        </div>
    )
}