import React from "react";
import { Typography, Paper, Box } from "@material-ui/core";

const SelectedTypeInfo = () => {
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h4">Selected type will be here</Typography>
      </Box>
    </Paper>
  )
}

export default SelectedTypeInfo;