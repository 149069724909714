import { createGlobalStyle } from 'styled-components';

// Woff2 imports
import FrutigerLTRoman from '../assets/woff2/FrutigerLT-Roman.woff2';
import FrutigerLTBlackCn from '../assets/woff2/FrutigerLT-BlackCn.woff2';
import FrutigerLTLight from '../assets/woff2/FrutigerLT-Light.woff2';
import FrutigerLTLightCn from '../assets/woff2/FrutigerLT-LightCn.woff2';
// import FrutigerLTCn from '../assets/woff2/FrutigerLT-Cn.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Frutiger LT';
        src: local('Frutiger LT'), url(${FrutigerLTRoman}) format('woff2');
    }
    @font-face {
        font-family: 'Frutiger LT Bold';
        src: local('Frutiger LT Bold'), url(${FrutigerLTBlackCn}) format('woff2');
    }
    @font-face {
        font-family: 'Frutiger LT Light';
        src: local('Frutiger LT Light'), url(${FrutigerLTLight}) format('woff2');
    }
    @font-face {
        font-family: 'Frutiger LT LightCom';
        src: local('Frutiger LT LightCom'), url(${FrutigerLTLightCn}) format('woff2');
    }
`