import React from 'react'
import { Grid, Box, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../../AppTheme';

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableCell-root': {
            backgroundColor: theme.palette.browse.reportCard.backgroundColor,
            borderBottomColor: theme.palette.etc.main
        }
    }
}));

function getDate(dateData){
    const dateObj = new Date(dateData)
    const dateString = dateObj.getUTCFullYear() +"/"+ (dateObj.getUTCMonth()+1) +"/"+ dateObj.getUTCDate()
    return dateString
}

export default function RevisionInfo({mode}) {
    const styles = useStyles();
    const [history, setHistory] = React.useState([])
    
    React.useEffect(() => {
        // Handle displaying only the latest rev for non hog platforms
        if( mode.schema !== 'hog' ) setHistory([mode.history[mode.history.length - 1]])
        else setHistory(mode.history)
    }, [mode])

    return (
        <Box
            borderRadius={theme.palette.browse.reportCard.borderRadius}
            style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
            padding={2}
        >
            <Grid container>
                {/* Header */}
                <Grid container justifyContent="center">
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Revision Notes</strong>
                    </Typography>
                </Grid>
                {/* Table */}
                <TableContainer className={styles.container}>
                    <Table size='medium' stickyHeader aria-label="Revision History Table" className={styles.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Revision</strong></TableCell>
                                <TableCell align="right"><strong>Date</strong></TableCell>
                                <TableCell align="right"><strong>Comment</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                history.map((row, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row" align='left'>
                                            {row.revision}
                                        </TableCell>
                                        <TableCell align="right">{getDate(row.date)}</TableCell>
                                        <TableCell align="right">{row.comment}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}
