import React, { createContext, useState, useEffect } from 'react';
import { PlatformSettings } from '../AppSettings';

const PlatformsContext = createContext();

const PlatformsContextProvider = ({ children }) => {
    const [platforms, setPlatforms] = useState();

    useEffect(() =>{
        // Fetches the subTypes from the FLH Jira project from the API-Server
        async function fetchPlatforms() {
            const params = `/api/issueTypes`;
            const iTypes = await fetch(window.REACT_APP_API_SERVER_ADDRESS + params);
            return await iTypes.json();
        }

        fetchPlatforms().then(issueTypes => {
            
            // Rewrite the 
            const plats = issueTypes.map(jiraSubTask => {
                // Clean up the platform name. Remove the prefixed 'FIX - ' and any underscores
                const name = jiraSubTask.replace(/FIX\s-\s/, '').replace(/_/g, ' ');
                
                // Common context values
                let requestDisabled = !PlatformSettings[name].requestable;
                let browseDisabled = !PlatformSettings[name].browsable;
                let accountDisabled = !PlatformSettings[name].preferable;
                let schema = "car";

                // Set the schema properties.
                if(!name.search(/^ColorSource.*/i)) schema = "cset";

                if(!name.search(/^Cobalt.*/i)) schema = "cobalt";

                if(!name.search(/^Hog\s4\sv3.*/i)) schema = "hogv4";

                if(!name.search(/^Hog\s4\sv4.*/i)) schema = "hogv5";

                return {
                    name,
                    jiraSubTask,
                    requestDisabled,
                    browseDisabled,
                    accountDisabled,
                    schema
                }
            });

            // Platform sorter
            plats.sort((a, b) => {
                var x = a.name.toLowerCase();
                var y = b.name.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
            });

            setPlatforms(plats);
        })
    },[])

    return (
        <PlatformsContext.Provider value={platforms}>
            {children}
        </PlatformsContext.Provider>
    );
};


export { PlatformsContext, PlatformsContextProvider }