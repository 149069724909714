import React from 'react';
import fixture from '../../../assets/fixture_icons/fixture.png';
import circle from '../../../assets/fixture_icons/circle.png';
import effect from '../../../assets/fixture_icons/effect.png';
import fog from '../../../assets/fixture_icons/Fog.png';
import media from '../../../assets/fixture_icons/media.png';
import moving_circle from '../../../assets/fixture_icons/moving circle.png';
import moving_mirror from '../../../assets/fixture_icons/moving mirror.png';
import moving_oct from '../../../assets/fixture_icons/moving oct.png';
import moving_oval from '../../../assets/fixture_icons/moving oval.png';
import moving_rect from '../../../assets/fixture_icons/moving rect.png';
import moving_rounded from '../../../assets/fixture_icons/moving rounded.png';
import moving_wash from '../../../assets/fixture_icons/moving wash.png';
import par_1 from '../../../assets/fixture_icons/par 1.png';
import par_2 from '../../../assets/fixture_icons/par 2.png';
import profile_1 from '../../../assets/fixture_icons/profile.png';
import rect from '../../../assets/fixture_icons/rect.png';
import scoop from '../../../assets/fixture_icons/scoop.png';
import square from '../../../assets/fixture_icons/square.png';
import strip from '../../../assets/fixture_icons/strip.png';
import strobe from '../../../assets/fixture_icons/strobe.png';
import trapezoid from '../../../assets/fixture_icons/trapezoid.png';
import triangle from '../../../assets/fixture_icons/triangle.png';
import wash from '../../../assets/fixture_icons/wash.png';
import { Request } from '../../../icons/icons';

const iconWidth = "60px"

export default function TileImage({ icon }) {
    switch(icon) {
        case 'circle':
            return <img src={circle} width={iconWidth} alt="circle"/>
        case 'effect':
            return <img src={effect} width={iconWidth} alt="effect"/>
        case 'fog':
            return <img src={fog} width={iconWidth} alt="fog"/>
        case 'media':
            return <img src={media} width={iconWidth} alt="media"/>
        case 'moving circle':
            return <img src={moving_circle} width={iconWidth} alt="moving_circle"/>
        case 'moving mirror':
            return <img src={moving_mirror} width={iconWidth} alt="moving_mirror"/>
        case 'moving oct':
            return <img src={moving_oct} width={iconWidth} alt="moving_oct"/>
        case 'moving oval':
            return <img src={moving_oval} width={iconWidth} alt="moving_oval"/>
        case 'moving rect':
            return <img src={moving_rect} width={iconWidth} alt="moving_rect"/>
        case 'moving rounded':
            return <img src={moving_rounded} width={iconWidth} alt="moving_rounded"/>
        case 'moving wash':
            return <img src={moving_wash} width={iconWidth} alt="moving_wash"/>
        case 'par 1':
            return <img src={par_1} width={iconWidth} alt="par_1"/>
        case 'par 2':
            return <img src={par_2} width={iconWidth} alt="par_2"/>
        case 'profile 1':
            return <img src={profile_1} width={iconWidth} alt="profile_1"/>
        case 'rect':
            return <img src={rect} width={iconWidth} alt="rect"/>
        case 'scoop':
            return <img src={scoop} width={iconWidth} alt="scoop"/>
        case 'square':
            return <img src={square} width={iconWidth} alt="square"/>
        case 'strip':
            return <img src={strip} width={iconWidth} alt="strip"/>
        case 'strobe':
            return <img src={strobe} width={iconWidth} alt="strobe"/>
        case 'trapezoid':
            return <img src={trapezoid} width={iconWidth} alt="trapezoid"/>
        case 'triangle':
            return <img src={triangle} width={iconWidth} alt="triangle"/>
        case 'wash':
            return <img src={wash} width={iconWidth} alt="wash"/>
        case 'request':
            return <Request style={{margin: 10}}/>;
        default:
            return <img src={fixture} width={iconWidth} alt="fixture"/>
    }
}
