import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HubTextField from '../common/HubTextField';
import { PlatformsContext } from '../../contexts/PlatformsContext';

function Manufacturer({ formik, setSection, requestData }) {
    const [manufacturersList, setManufacturesList] = React.useState([]);

    const [disable, setDisable] = React.useState(false);
    const [freesolo, setFreeSolo] = React.useState(false)

    // Hook used to set the Manufacturer dropdown to disabled.
    React.useEffect(() => {
        if( requestData ) {
            if(requestData.disable === 'manufacturer' || requestData.disable === 'both') {
                setDisable(true);
                setFreeSolo(true);
            }
        }
    }, [requestData]);

    // Hook used to perform a http request to the api-server to retrieve the
    // list of available manufacturers. Updates when the platform is changed.
    React.useEffect(() => {
        if( formik.values.platform !== null ) {
            const mList = [...requestData.manufacturers, "Other"];
            setManufacturesList(mList.sort());

            if( "manufacturer" in requestData ) formik.setFieldValue("manufacturer", requestData.manufacturer);
        }
        // eslint-disable-next-line
    },[formik.values.platform])

    return (
        <Autocomplete
            value={formik.values.manufacturer}
            filterSelectedOptions
            options={manufacturersList}
            disabled={disable}
            freeSolo={freesolo}
            onChange={(e, manufacturer) => formik.setFieldValue("manufacturer", manufacturer)}
            onFocus={() => setSection("manufacturer")}
            onMouseOver={() => setSection("manufacturer")}
            onBlur={() => setSection(null)}
            renderInput={params => 
                <HubTextField
                    {...params}
                    variant="outlined"
                    size={"small"}
                />
            }
        />
    )
}

// Hack to wait for the Platforms Context to load before using loading the page
export default function ManufacturerContextLoader({ formik, setSection, requestData }) {
    const platformCtx = React.useContext(PlatformsContext)
    return(
        platformCtx && platformCtx ?
            <Manufacturer formik={formik} setSection={setSection} requestData={requestData}/>
        :
            null
    )
}