// An object of all current platforms and what functionality they can perform on the site.
// preferable : Allows the platform to be displayed in the 'Preferred Platforms' list in the users account page.
// browsable : Platforms with this property set to true are available in the 'Platforms' list on the browse page.
// requestable : Plaforms with this property set to true are available in the 'Platforms' list on the request page.
// min_date : This is the minimum amount of timeframe (in days) needed preceeding the selected date in the 'Needs by Date' of the request page.
// due_date : This is the date which is set (in days). Cannont be less than the min_date

const Eosv2 = {
    preferable: false,
    browsable: false,
    requestable: false,
    min_date: 21,
    due_date: null,
    supported_models: ["Eos Ti", "Gio", "Gio @5", "Ion", "Ion Xe", "Element", "Element 2", "Eos Classic", "ETCnomad", "ETCnomad Puck"],
    request: {
        platform_info: {
            header: "This platform encompasses the following models: ",
            help: "For more specific instructions on how to install fixture profiles on the Eos platform products please refer to the “Fixture Library” help topic in the Eos help manual.",
            compatibility: "Eos profiles are compatible with Eos v2.9 and greater."
        },
        hide_duedate: true,
        hide_mode: true
    },
    browse: {
        downloadCtx: "Eos",
        description: {
            header: 'The Eos control platform encompasses the following products running Eos v2.9 and greater',
            help: "For more specific instructions on how to install fixture profiles on the Eos platform products please refer to the “Fixture Library” help topic in the Eos help manual.",
            notes: ""
        }
    }
}

const Eosv3 = {
    preferable: true,
    browsable: true,
    requestable: true,
    min_date: 21,
    due_date: null,
    supported_models: ["Apex 5", "Apex 10", "Apex 20", "Apex Processor", "Eos Ti", "Gio", "Gio @5", "Eos RPU3", "Ion Xe", "Ion Xe 20", "Ion Xe RPU", "Ion Classic (Win 7)", "Element 2", "Element (Win7)", "Eos Remote Interface", "RVI3 (Win7)", "ETCnomad", "ETCnomad Puck", "Eos on Mac and PC"],
    request: {
        additional_text: true,
        platform_info: {
            header: "This platform encompasses the following models: ",
            help: "For more specific instructions on how to install fixture profiles on the Eos platform products please refer to the “Fixture Library” help topic in the Eos help manual.",
            compatibility: "Eos profiles are compatible with Eos v3.2.0 or greater."
        },
        hide_duedate: true,
        hide_mode: true
    },
    browse: {
        downloadCtx: "Eos",
        description: {
            header: "The Eos Family of controllers running v3.0 and higher include:",
            help: "Eos does not support the download of individual fixtures from the Fixture Library Hub – a full library will be downloaded including all up-to-date fixtures.",
            notes: ""
        }
    }
}

module.exports = { Eosv2, Eosv3 }