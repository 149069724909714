import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AccountNavigation from './AccountNavigation';
import Information from './Information';
import Requests from './Requests';
// import Uploads from './Uploads';
// import Libraries from './Libraries';
import { Redirect } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0px 15px 0px 15px"
    },
    left: {
        width: "15%"
    },
    right: {
        width: "60%"
    }
}));

export default function Profile(){
    const classes = useStyles();
    const userCtx = React.useContext(UserContext);
    const [section, setSection] = React.useState('userInfo');

    // Different Account Sections
    const sections = {
        'userInfo': <Information/>,
        'userRequests': <Requests/>,
        // 'userUploads': <Uploads userData={userData} />,
        // 'userLibs': <Libraries userData={userData} />
    }

    // Redirect to the landing page if the user is not logged in
    if (!userCtx.isAuthenticated) return <Redirect to="/" />;

    return (
        <div className={classes.root}>
            <Grid container justifyContent='center'>
                {/* Account Nav */}
                <Grid item className={classes.left}>
                    <AccountNavigation section={section} setSection={setSection}/>
                </Grid>

                {/* Account Content */}
                <Grid item className={classes.right}>
                    {
                        userCtx.isAuthenticated ? 
                            sections[section]
                        : null
                    }
                </Grid>
            </Grid>
        </div>
    )
}
