import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core';
import theme from '../../../../AppTheme';

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        marginTop: 5
    },
    container: {
        // maxHeight: 200,
    },
    item: {
        padding: 5
    },
    table: {
        '& .MuiTableCell-root': {
            borderBottomColor: theme.palette.etc.main
        }
    }
}));

export default function CompoundPartInfo({compound}) {
    const styles = useStyles();
    // console.log(compound)
    return(
        <div>
            <Grid item xs>
                <Box
                    borderRadius={theme.palette.browse.reportCard.borderRadius}
                    style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                    padding={2}
                >
                    {/* Header */}
                    <Grid container justifyContent="center">
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>{`DMX Footprint (${compound.footprint} ch)`}</strong>
                        </Typography>
                    </Grid>

                    {/* Table */}
                    <TableContainer>
                        <Table size='medium' stickyHeader aria-label="Compound Parts Table" className={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><strong>Part</strong></TableCell>
                                    <TableCell align="right"><strong>Type</strong></TableCell>
                                    <TableCell align="right"><strong>DMX Footprint</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    compound.parts.map((part, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell component="th" scope="row" align='left'>
                                                {
                                                    part.number === "0" ? "." : part.number.replace("0", "")
                                                }
                                            </TableCell>
                                            <TableCell align="right">{part.type}</TableCell>
                                            <TableCell align="right">{part.mode.footprint}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </div>
    )
}