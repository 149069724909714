
/**
 * Number.prototype.format(n, x)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
// Number.prototype.format = function(n, x) {
//     var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
//     return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
// };

// This convienence function simply returns the primary email address
// found in the login radius user.Email property
export function getUsersPrimaryEmailAddress(emailData){
    // Get the users primary email address
    let primaryEmail = null;
    for( const email of emailData ){
        if( email.Type === "Primary" ) primaryEmail = email.Value;
    }
    return primaryEmail;
}


// Simple helper function to accept a date and return its yyyy-mm-dd format
export function getYMD(date){
    if(date) return new Date(date).toISOString().split('T')[0]
}


// This function is used to handle disabling the platform items in
// the Autocomplete components where they are used
export function handlePlatformDisable(option, PLATFORMS){
    // console.log("handlePlatformDisable :", option)

    // Create an array of disabled platform items
    const disabled = PLATFORMS.map(platform => {
        if(platform.disabled) return platform.name 
        return null;
    })

    // Mark the platform disabled in the platform list
    if( disabled.includes(option) ) return true;
        
    // Mark the platform as enabled in the platform list
    return false;
}


// This function is used when making calls back to the API Server. It
// simply lets toggles which schema to query
export function getSchemaForPlatform(platformName, PLATFORMS){
    // console.log("getSchemaForPlatform :", platformName)

    // Get the selected platform
    const p = PLATFORMS.find(platform => {
        if( platform.name === platformName ) return true;
        return false;
    })
    return p.schema;
}


export function getPlatformObject(platformName, PLATFORMS){
    // console.log("getPlatformObject :", platformName)
    
    // Get the selected platform
    const p = PLATFORMS.find(platform => {
        if( platform.name === platformName ) return true;
        return false;
    })
    return p;
}


// Simple function to convert a base64 encoded file into a data blob
export function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: '' });
}