import React from 'react'
import { IconButton, TextField } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import theme from '../../AppTheme';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
    root: {
        '& label': {
            color: "white",
            fontSize: theme.palette.browse.fields.fontSize,
        },
        '& label.Mui-focused': {
            color: "white",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.etc.main,
        },
        '& .MuiOutlinedInput-root': {
            color: "white",
            paddingRight: "5px",
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.etc.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.etc.main,
            }
        },
    }
})(TextField);

const useStyles = makeStyles((theme) => ({
    field: {
        backgroundColor: theme.palette.background.inner, 
        whiteSpace: "nowrap",
        color: "white",
        '& .MuiOutlinedInput-root': {
            fontSize: theme.palette.browse.fields.fontSize,
            '& fieldset': {
                borderColor: theme.palette.browse.fields.borderColor,
                borderRadius: theme.palette.browse.fields.borderRadius
            }
        },
    }
}));

export default function ProductSearchBar({ 
    productSearch, 
    setProductSearch, 
    disabled,
}){
    const styles = useStyles();
    return (
        <div style={{margin: "15px 15px 15px 15px"}}>
            <CssTextField
                className={styles.field}
                variant="outlined"
                fullWidth
                size={'small'}
                disabled={disabled}
                label="Filter by Product"
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            style={{padding: 0}}
                            size={'small'}
                            color="primary"
                            aria-label="clear product search"
                            component="span"
                            onClick={() => setProductSearch("")}
                        >
                            <Cancel style={{color: "gray"}} />
                        </IconButton>
                    ),
                }}
            />
        </div>
    )
}
