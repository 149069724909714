import React from "react";
import { Box, Button, FormControlLabel, Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Checkbox from '@material-ui/core/Checkbox';
import { FileCopy } from "@material-ui/icons";
import UserUploads from "./UserUploads";
import SelectedTypeInfo from "./SelectedTypeInfo";

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      transform: "scale(1.5)",
      padding: "20px",
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const UploadPage = () => {
  const [fileName, setFileName] = React.useState("No file selected");
  const [consentGiven, setConsent] = React.useState(false)

  const handleChange = (event) => {
    setConsent(event.target.checked)
  }

  const getFileName = (event) =>{
    setFileName(event.target.files[0].name);
  }

    return (
        <Grid
            container
            style={{ margin: "40px 0px 40px 0px" }}
            justifyContent="space-around"
        >
            <Grid item sm={4}>
              {/* Selecting File */}
                <Paper style={{ margin: "0px 0px 10px 0px" }}>
                    <Box p={2}>
                        <Typography variant="h4" gutterBottom>Upload Your Library File</Typography>
                        <Typography gutterBottom>Use the browse button below to select a schema validated XML file to upload a Hog 4 fixture type that you have created.</Typography>
                        <Typography gutterBottom>Once a file is uploaded the origin of the fixture types contain within will be modified in the share database to reflect your ETC Fixture Share username. To delete fixture types you have previously uploaded select the type from the my uploads sections on this page and select the delete button.</Typography>
                        <Typography gutterBottom>A single XML file can contain multiple fixture type definitions across different fixture products.</Typography>
                        <Typography gutterBottom>It is highly recommended that you use the fixture builder installed in Hog 4 OS v3.10.0 and greater to validate and test a fixture profile before uploading it to the share site. For more instructions uploading and managing your content on the share site please visit the share site help page.</Typography>
                    </Box>
                    <Box component="label" p={2} display="flex" justifyContent="space-between" alignItems="center">
                      <input
                            style={{ display: 'none' }}
                                type="file"
                                id="library"
                                name="library"
                                accept=".xml"
                                onChange={getFileName}
                            />
                        <Button
                            variant="contained"
                            color="default"
                            size="large"
                            startIcon={<FileCopy />}
                            component="span"
                        >
                            Browse
                        </Button>
                        <Typography component="span">Selected File: {fileName}</Typography>
                    </Box>
                    <Box p={2}>
                    <FormControlLabel
                        control={<GreenCheckbox checked={consentGiven} onChange={handleChange} name="Upload consent" />}
                        label="I hereby consent to share the contents of the files I have selected for submission and understand that the information contained will be made available for other users of the ETC Fixture sharing site. Furthermore, I acknowledge that ETC may retain copies of the work I have submitted and may use this data as part of the fixture type profile information installed on Hog 4 series console. Legal stuff should continue here..."
                    />
                    </Box>
              {/* Actually consent and upload to the cloud*/}
                    <Box p={2}>
                        <Button
                            variant="contained"
                            size="large"
                            color={(fileName === "No file selected") ? "default" : "secondary"}
                            startIcon={<CloudUploadIcon />}
                            disabled={(fileName === "No file selected") ? true : false}
                        >
                            Submit
                        </Button>
                    </Box>
                </Paper>
            </Grid>
            <Grid item sm={6}>
                <UserUploads/>
                <SelectedTypeInfo/>
            </Grid>
        </Grid>
    );
};

export default UploadPage;
