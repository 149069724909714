import React from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { Box, Grid, Typography, Snackbar, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AttachFile, Description, PictureAsPdf, Close } from '@material-ui/icons';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

// MUI Overrides
const theme = createTheme({
    overrides: {
        MuiDropzoneArea: {
            root: {
                minHeight: "165px"
            },
            text: {
                fontSize: 16
            }
        }
    }
});

// Handles selecting the correct icon according to the file type
const handlePreviewIcon = (fileObject) => {
    const {type} = fileObject.file

    switch (type){
        case"application/msword":
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <Description fontSize="large"/>
        case "application/pdf":
            return <PictureAsPdf fontSize="large"/>
        default:
            return <AttachFile fontSize="large"/>
    }
}

// Const of accepted file types
const acceptedFiles = ['application/pdf', 'image/jpeg', 'image/png'];

export default function DocUploader({ formik, setSection }) {
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');

    // Handles checking added files
    function handleAdd(newFiles) {
        // Array used for adding files
        const addFiles = [];
        // String used for displaying custom snackbar messages
        let strFiles = "";

        // Examine the file(s) to add
        for(const f of newFiles) {
            // check if the file already exists in formik
            const found = formik.values.documentation.find(fl => fl.file.name === f.file.name);

            if( !found ) {
                // Check the file size
                if( f.file.size !== 0 ) {
                    strFiles += `${f.file.name} `
                    addFiles.push(f);
                }
                else {
                    setSeverity('error')
                    setMsg(`File "${f.file.name}" rejected!! Zero byte file detected.`);
                    setOpen(!open);
                }
            }
        }
        
        if(addFiles.length) {
            // Update the formik field
            formik.setFieldValue('documentation', [...formik.values.documentation, ...addFiles])
            setSeverity('success')
            setMsg(`"${strFiles}" added successfully.`);
            setOpen(!open);
        }
    }

    // Handles removing any files
    function handleDelete(removeFile) {
        // Filter out the removed file
        const files = formik.values.documentation.filter(f => f.file.name !== removeFile.file.name);
        formik.setFieldValue('documentation', files);

        // if there are no files selected to upload then display the 
        // error message below the dropzone
        if( formik.values.documentation ) formik.setFieldTouched('documentation')

        // Set the message and display
        setSeverity('info')
        setMsg(`"${removeFile.file.name}" successfully removed.`);
        if( !open ){
            setOpen(!open);
        }
    }

    // Close action used to display a close button to close the snackbar if open
    const closeAction = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(!open)}>
            <Close fontSize="small" />
        </IconButton>
    )

    /* Snackbar used for custom message handling */
    const CustomMessages = () => {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={() => setOpen(!open)}
                action={closeAction}
            >
                <Alert onClose={() => setOpen(!open)} severity={severity} variant="filled">{msg}</Alert>
            </Snackbar>
        )
    }

    return (
        <Grid container item xs style={{marginTop: 10}}>
            <Grid container item xs={4} justifyContent="flex-end" style={{padding: "8px 15px 0px 0px"}}>
                <Typography variant="body1">Documentation</Typography>
            </Grid>
            <Grid item xs={6} onMouseEnter={() => setSection("documentation")}>
                <MuiThemeProvider theme={theme}>
                    <DropzoneAreaBase
                        dropzoneText={"Drag or click to add DMX Protocol Documents."}
                        fileObjects={formik.values.documentation}
                        onAdd={(f) => handleAdd(f)}
                        onDelete={(f) => handleDelete(f)}
                        acceptedFiles={acceptedFiles}
                        getPreviewIcon={handlePreviewIcon}
                        showAlerts={['error']}  // Only display errors, all others are self messaged
                        
                        // Preview in dropzone
                        showPreviewsInDropzone
                        showFileNames
                        
                        // Dropzone preview properties
                        previewGridProps={{
                            container: { 
                                spacing: 3, 
                                direction: 'column', 
                                alignContent: 'center' 
                            }
                        }}

                        // File requirements
                        filesLimit={3}
                        maxFileSize={10000000}
                    />
                </MuiThemeProvider>
                {
                    ( formik.touched.documentation && Boolean(formik.errors.documentation) ) 
                    ? <Box>
                        <Typography variant="caption" color="error">{formik.errors.documentation}</Typography>
                    </Box> 
                    : null
                }
            </Grid>
            <CustomMessages/>
        </Grid>
    )
}
