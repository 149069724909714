import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Chip, Divider, Button, Box } from '@material-ui/core';
import { Download } from '../../../icons/icons';
import theme from '../../../AppTheme';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 10,
    },
    top: {
        margin: "10px 5px 0px 5px",
        width: "80%",
        minWidth: "325px"
    },
    dlLinksContainer: {
        margin: "20px 0px 0px 0px",
    },
    librarianCommentContainer: {
        margin: "20px 0px 0px 0px",
    },
    headers: {
        fontSize: theme.palette.browse.reportCard.fontSize,
        marginBottom: 15
    },
    containers: {
        margin: "0px 5px 0px 5px"
    },
    dataContainer: {
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
        padding: 20
    },
    divide: {
        margin: "5px 0px 5px 0px",
        backgroundColor: theme.palette.etc.main,
    },
    dlButton: {
        borderRadius: 0,
        marginRight: 5,
        backgroundColor: theme.palette.etc.main,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.etc.hover,
        }
    },
}));

export default function RequestReportCard({ request }) {
    const styles = useStyles();

    // Request item data object
    const requestParams = [
        {
            name: "Platform",
            value: request.platform
        },
        {
            name: "Status",
            value: request.status.name
        },
        {
            name: "Manufacturer",
            value: request.manufacturer
        },
        {
            name: "Product",
            value: request.product
        },
        {
            name: "Personality / Mode",
            value: request.mode
        },
        {
            name: "DMX Footprint",
            value: request.footprint
        }
    ]

    // Request item template
    const RequestItem = ({item}) => (
        <div>
            <Grid container item direction='row' justifyContent='space-between'>
                <Typography variant='body1' style={{margin: "0 0 0 10px"}}>{item.name}</Typography>
                <Typography variant='body1' style={{margin: "0 10px 0 0"}}>{item.value}</Typography>
            </Grid>
            <Divider className={styles.divide}/>
        </div>
    )

    return (
        <Grid container className={styles.root} alignItems='center' direction='column'>
            <Grid container direction='column' item className={styles.top}>
                {/* Header */}
                <Grid container item justifyContent='center'>
                    <Typography className={styles.headers}>
                        Profile Information
                    </Typography>
                </Grid>
                {/* Request Data */}
                <Grid container item direction='column' className={styles.dataContainer}>
                    <Grid container item direction='row' justifyContent='center' style={{margin: "0px 0px 10px 0px"}}>
                        <Chip size='medium' label={`Request ID: ${request.key}`} style={{color: "white", backgroundColor: theme.palette.etc.main, fontWeight: 'bold'}}/>
                    </Grid>
                    
                    {
                        requestParams.map((item, idx) => (
                            <RequestItem key={idx} item={item}/>
                        ))
                    }
                </Grid>
            </Grid>

            {/* Librarian Comment */}
            {
                !request.librarianComment ? null :
                <Box display="flex" flexDirection="column" className={styles.librarianCommentContainer}>
                    <Box display="flex" justifyContent="center">
                        <Typography className={styles.headers}>
                            Librarian Comment(s)
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant='body1' style={{fontStyle: 'italic'}}>
                            {request.librarianComment}
                        </Typography>
                    </Box>
                </Box>
            }

            {/* Download Link */}
            {
                !request.downloadLinks.length ? null :
                    <Grid container direction='column' item className={styles.dlLinksContainer}>
                        <Grid container item justifyContent='center'>
                            <Typography className={styles.headers}>
                                Download Link(s)
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent='center'>
                            {
                                request.downloadLinks.map((link, idx) => {
                                    // RegEx to extract the filename from the link
                                    const regex = /\w+\.\w+$/gm;
                                    const match = regex.exec(link);
                                    return (
                                        <a href={link} key={idx} style={{textDecoration: "none"}}>
                                            <Button
                                                variant="contained"
                                                className={styles.dlButton}
                                                startIcon={<Download />}
                                                size="small"
                                            >
                                                {match[0]}
                                            </Button>
                                        </a>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
            }
        </Grid>
    )
}
