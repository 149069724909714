import { createTheme } from "@material-ui/core/styles";

let theme = createTheme({
    typography: {
        fontFamily: "Frutiger LT, Arial, sans-serif"    // Typography font
    },
    palette: {
        primary: {
            main: "#000"
        },
        secondary: {
            main: "#00aeef",
        },
        background: {
            main: "black",
            inner: "#1F2228"
        },
        etc: {
            main: "#00aeef",
            hover: "#005e80"
        },
        tile: {
            official: "#00aeef",
            request: "#CE102C",
            hover: "#990000"
        },
        icons: {
            primary: "#00aeef",
            secondary: "#c1c1c1"
        },
        browse: {
            fields: {
                fontSize: "11pt",
                manufacturerChip: "9pt",
                borderColor: "transparent",
                borderRadius: "0px"
            },
            products: {
                fontSize: "10pt",
                manufacturerFontSize: "8pt",
                listHeight: 750
            },
            tile: {
                headerProduct: "16pt",
                headerManufacturer: "12pt",
                officialInfo: "10pt",
                requestInfo: "10pt",
                streamHeader: "14pt",
                width: 215,
                infoSpacing: "1px 0px 1px 10px",
                containerHeight: 862
            },
            reportCard: {
                borderRadius: "0px",
                backgroundColor: "#f5f5f5",
                height: 854,
                fontSize: "14pt"
            }
        },
        account: {
            headers: "14pt",
            label: "12pt",
            value: "12pt"
        },
        contrastThreshold: 3,
    },
});

export default theme;