import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: "25px 0px 0px 25px"
    }
})

export default function EtcOne(){
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container direction='column'>
                <Typography variant='h5' gutterBottom>
                    What is ETC ONE?
                </Typography>
                <Typography variant='body1'>
                    ETC One grants you access to multiple ETC platforms with just one set of login credentials. 
                    These platforms include My ETC, ETC online shops, the ETC Community Forums, ETC Learning 
                    Stage, ETC’s Support Site, and this Fixture Library Hub site.
                </Typography>
            </Grid>
        </div>
    )
}