import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { PlatformsContext } from '../../contexts/PlatformsContext';
import HubTextField from '../common/HubTextField';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "15px 0px 0px 0px",
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.inner,
            color: "white",
            fontSize: theme.palette.browse.fields.fontSize,
            borderRadius: theme.palette.browse.fields.borderRadius,
            '& fieldset': {
                borderColor: theme.palette.browse.fields.borderColor,
            }
        },
        '& .MuiFormLabel-root': {
            color: "white",
            fontSize: theme.palette.browse.fields.fontSize,
        },
        '& .MuiSvgIcon-root': {
            color: "gray"
        }
    },
    paper: {
        backgroundColor: theme.palette.background.inner,
        color: "white",
        '& .MuiListItem-root.Mui-active': {
            "&:hover": {
                backgroundColor: theme.palette.etc.main
            }
        },
        '& .MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.etc.main,
            color: "white",
        },
        '& .MuiMenuItem-root': {
            "&:hover": {
                backgroundColor: theme.palette.etc.hover
            }
        }
    }
}));

export default function PlatformSelector({ platform, setPlatform }) {
    const classes = useStyles();
    const PLATFORMS = React.useContext(PlatformsContext);
    return (
        <div style={{margin: "0px 15px 0px 15px"}}>
            <HubTextField
                className={classes.root}
                variant="outlined"
                select
                fullWidth
                size={'small'}
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                label="Platform"
                SelectProps={{ MenuProps: {classes: {paper: classes.paper}} }} 
            >
                {
                    PLATFORMS.map((p, idx) => {
                        if(p.disabled) return null;
                        if(p.browseDisabled) return null;

                        else {
                            return (
                                <MenuItem 
                                    key={idx} 
                                    value={p.name}
                                >
                                    {p.name}
                                </MenuItem>
                            )
                        }
                    })
                }
            </HubTextField>
        </div>
    )
}
