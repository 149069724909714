import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: "25px 0px 0px 25px"
    },
    listItem: {
        padding: "10px 0px 10px 0px"
    }
})

const numberedItems = [
    "Click the Fixture Library Hub link at the top of the Fixture Library Hub site.",
    "Use the browser’s platform filter to choose a console platform. A platform information page will appear to right which explains which console hardware and software versions are compatible with the platform. Please note that some platforms are available for requesting but not available for browsing.",
    "Use the manufacturer and product filters to find a fixture product.",
    "When you select a fixture product in the browser a tile view will appear that shows the official profiles and open requests for that product. Each tile represents a single fixture profile for a specific mode.",
    "Click on a tile to see a summary report of the profile with important detail about the profile.",
    "To return to the product’s tile view click on the back arrows at the top of the summary page."
]

export default function Browsing(){
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container direction='column'>
                <Typography variant='h5' gutterBottom>
                    Browsing for Profiles
                </Typography>
                <Typography variant='body1'>
                    Anyone can browse and download fixture profiles on the ETC Fixture Library Hub using the steps below:
                </Typography>
                <ol>
                    {
                        numberedItems.map((item, idx) => (
                            <li key={idx} className={classes.listItem}>
                                <Typography variant='body1'>{item}</Typography>
                            </li>
                        ))
                    }
                </ol>
            </Grid>
        </div>
    )
}