import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import { PlatformsContext } from '../../contexts/PlatformsContext';
import HubTextField from '../common/HubTextField';
import { getYMD } from '../../config/helperFuns';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { HandleStatus, StatusHandlerDefault } from '../common/HandleStatus';
import { getUsersPrimaryEmailAddress } from '../../config/helperFuns';
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px 0 0 25px"
    },
    autoselect: {
        width: "75%"
    },
    buttons: {
        margin: "20px 0px 0px 0px"
    },
    button: {
        margin: 20
    }, 
    headers: {
        fontSize: theme.palette.account.headers
    },
    labels: {
        fontSize: theme.palette.account.label
    },
    values: {
        fontSize: theme.palette.account.value
    }
}));

export default function UserInformation(){
    const classes = useStyles();
    const userCtx = React.useContext(UserContext);
    const PLATFORMS = React.useContext(PlatformsContext);
    const [editMenuAnchor, setEditMenuAnchor] = React.useState(null);
    const [platform, setPlatform] = React.useState(userCtx.preferredPlatform);
    const [editMode, setEditMode] = React.useState(true);
    const [statusHandler, setStatusHandler] = React.useState(StatusHandlerDefault);

    // Function used to perform a request to the API server to update the
    // users preferences. It then handles displaying status after execution.
    function saveEdit() {
        // Checks if the user is trying to update their current default
        if( platform === userCtx.preferredPlatform ) {
            // Give status
            setStatusHandler({
                status: true, 
                message: "Same preference selected. Skipping preference update.", 
                severity: "info"
            });
            setEditMode(true);
            
            // Hide the snackbar after 5 secs
            setTimeout(() => {
                setStatusHandler(StatusHandlerDefault);
            }, 5000);
            return
        }

        // Turn off edit mode
        setEditMode(true);

        // console.log("Updating the user preference")
        fetch(window.REACT_APP_API_SERVER_ADDRESS + `/api/user/${userCtx.user.ID}?option=preferences`, {
            method: 'PUT',
            body: JSON.stringify({
                preferredPlatform: platform
            }),
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json' 
            },
        })
        .then(async (res) => {
            const rtn = await res.json();
            
            // Display status based on the return
            if(!rtn.success) {
                setStatusHandler({
                    status: rtn.success, 
                    message: "The update failed. Please try again.", 
                    severity: "error"
                });
                return;
            }

            setStatusHandler({
                status: rtn.success, 
                message: "Success!", 
                severity: "success"
            });

            // Update the User Context preferred platform
            userCtx.updatePlatform(platform);
            return;
        })
        .then(() => {
            // Hide the status
            setTimeout(() => {
                setStatusHandler(StatusHandlerDefault);
            }, 5000);
        })
        .catch(err => {
            console.log("ERROR : ", err);
        });
    }

    // Function used to cancel any updates. It removes the buttons and sets
    // the preferred platform field back to its current value in the db.
    function cancelEdit() {
        setPlatform(userCtx.preferredPlatform);
        setEditMode(true);
    }

    // Array of the user info to display
    const userInfo = [
        {
            name: "Username",
            value: userCtx.user.UserName
        },
        {
            name: "Name",
            value: `${userCtx.user.FirstName} ${userCtx.user.LastName}`
        },
        {
            name: "Email",
            value: getUsersPrimaryEmailAddress(userCtx.user.Email)
        },
        {
            name: "User Since",
            value: getYMD(userCtx.user.CreatedDate)
        },
        {
            name: "Last Login",
            value: getYMD(userCtx.user.LastLoginDate)
        },
    ]

    return (
        <div className={classes.root}>
            <Grid item container direction="column">
                {/* Header */}
                <Grid container>
                    <Grid container item xs={10} alignContent='center'>
                        <Typography variant="h5" style={{marginLeft: 10}}>
                            Profile Info
                        </Typography>
                    </Grid>
                    {/* Edit Menu */}
                    <Grid container item xs={2} justifyContent='flex-end'>
                        <IconButton 
                            aria-label="edit" 
                            onClick={(e) => setEditMenuAnchor(e.currentTarget)}
                            style={{padding: 5}}
                        >
                            <MoreHorizIcon fontSize="large" color='primary' />
                        </IconButton>
                        <Menu
                            anchorEl={editMenuAnchor}
                            keepMounted
                            open={Boolean(editMenuAnchor)}
                            onClose={() => setEditMenuAnchor(null)}
                        >
                            <MenuItem onClick={() => setEditMode(false)}>
                                Edit Preferences
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

                <Grid container direction="column" style={{marginLeft: 15}}>
                    {/** User info */}
                    <Grid item>
                        <Typography className={classes.headers}>Your Information</Typography>
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        {
                            userInfo.map((info, idx) => (
                                <Grid key={idx} item container spacing={4}>
                                    <Grid item xs={3} container justifyContent="flex-end">
                                        <Typography className={classes.labels}>{info.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.values}>{info.value}</Typography>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>

                    {/** Preferences */}
                    { 
                        PLATFORMS && PLATFORMS ? 
                            <div style={{marginTop: 30}}>
                                <Grid item>
                                    <Typography className={classes.headers} gutterBottom>Your Preferences</Typography>
                                </Grid>
                                {/* Platform Selector */}
                                <Grid container direction='row' alignItems="center">
                                    <Grid container item xs={4}  style={{padding: "0px 15px 0px 0px"}} justifyContent="flex-end">
                                        <Typography variant="body1">Preferred Platform</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <HubTextField
                                            variant="outlined"
                                            select
                                            size="small"
                                            value={platform}
                                            fullWidth
                                            onChange={(e) => setPlatform(e.target.value)}
                                            disabled={editMode}
                                        >
                                            {
                                                PLATFORMS.map((p, idx) => {
                                                    if(p.accountDisabled) return null;
                                                    return (
                                                        <MenuItem 
                                                            key={idx} 
                                                            value={p.name}
                                                        >
                                                            {p.name}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </HubTextField>
                                    </Grid>
                                </Grid>
                            </div>
                        : 
                            null
                    }
                </Grid>
                
                {/* Edit Mode Buttons */}
                {
                    !editMode ? 
                        <Grid container item justifyContent="center" className={classes.buttons}>
                            <Button 
                                className={classes.button} 
                                variant="contained" 
                                color="primary"
                                onClick={saveEdit}
                            >Save</Button>
                            <Button 
                                className={classes.button} 
                                variant="contained" 
                                color="primary"
                                onClick={cancelEdit}
                            >Cancel</Button>
                        </Grid>
                    :
                        null
                }

                {/* Status/Error Handling */}
                <HandleStatus 
                    status={statusHandler.status} 
                    message={statusHandler.message}
                    severity={statusHandler.severity}
                />
            </Grid>
        </div>
    )
}