import React from "react";
import { DialogTitle, DialogContent, Dialog, Button, Grid, Typography, Modal, Link, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import CustomerSection from './CustomerSection';
import FixtureSection from "./FixtureSection";
import CommentsDelivery from "./CommentsDelivery";
import FieldHelper from "./FieldHelper";
import DocUploader from "./DocUploader";
import ReCaptch from "./ReCaptcha";
import Platform from "./Platform";
import ConfirmationDialog from "./ConfirmationDialog";
import { getPlatformObject, b64toBlob } from '../../config/helperFuns';
import { PlatformsContext } from '../../contexts/PlatformsContext';
import { DownloadsContextProvider } from '../../contexts/DownloadsContext';
import { PlatformSettings } from '../../AppSettings';
import { CommonValidationSchema, NullDueDateSchema, OtherManufactureSchema, RequiredOtherManufactureSchema, ModeAllSchema }  from './ValidationSchemas';

// Reference to the Recaptcha
const recaptchaRef = React.createRef();

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 900
    },
    left: {
        width: "60%",
        paddingBottom: 30,
        '&::-webkit-scrollbar': {
            width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: theme.palette.etc.main,
            outline: '0px solid slategrey'
        }
    },
    right: {
        width: "35%",
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
    }
}));

function RDialog(props) {
    const {reqDialogOpen, setReqDialogOpen, requestData} = props;
    const styles = useStyles();
    const platformsCtx = React.useContext(PlatformsContext);
    const [section, setSection] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [submitProgress, setSubmitProgress] = React.useState(false);
    const [confirmation, setConfirmation] = React.useState({});
    const [validationSchema, setValidationSchema] = React.useState();

    // The formik defaults
    let formikDefaults = {
        name: "",
        email: "",
        platform: requestData.platform,
        manufacturer: null,
        other: '',
        fixtureName: '',
        mode: '',
        footprint: '',
        documentation: [],
        private: false,
        dueDate: null,
        comment: '',
        recaptcha: ''
    };
    
    // Formik Form
    const formik = useFormik({
        initialValues: formikDefaults,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (formData) => {
            // Build formData object to pass to the API-Server
            let fData = new FormData();
            fData.append('name', formData.name);
            fData.append('email', formData.email);
            fData.append('platform', formData.platform); // Used in the automated emails only
            fData.append('manufacturer', formData.manufacturer);
            if(formData.other !== '') fData.append('other', formData.other);
            fData.append('fixtureName', formData.fixtureName);
            fData.append('mode', formData.mode);
            fData.append('footprint', JSON.parse(formData.footprint));
            fData.append('subTask', getPlatformObject(formData.platform, platformsCtx).jiraSubTask);

            // Append the documentation files to the form data
            for(const f of formData.documentation) {
                fData.append('documentation', b64toBlob(f.data), f.file.name);
            }
            // Optional form elements
            if(formData.private) fData.append('private', formData.private);
            if(formData.dueDate !== '') fData.append('dueDate', formData.dueDate);
            if(formData.comment !== '') fData.append('comment', formData.comment);

            // Turn on the progress indicator
            setSubmitProgress(true);

            // Performing the post to the api server for the XML download
            fetch(window.REACT_APP_API_SERVER_ADDRESS + '/api/request', {
                method: 'POST',
                body: fData,
                headers: {
                    'Accept': 'application/json'
                }
            })
            .then( async (res) => {
                const rtn = await res.json();
                
                // Add the platform to the return data so the proper buttons
                // can be displayed in the confirmation dialog for the appropriate platform
                rtn.platform = formData.platform;

                setConfirmation(rtn);

                // Turn off the progress indicator
                setSubmitProgress(false);

                // Open the Confirmation Dialog
                setOpenDialog(true);
            })
            .catch(err => {
                console.log(err);
            });
        },
    });

    // Hook used to pre-seed the fixture name field of the fixture request form when the 
    // 'Create A Request' button is pressed
    React.useEffect(() => {
        if( requestData ) {
            if( 'product' in requestData ) formik.setFieldValue("fixtureName", requestData.product);
            if( 'platform' in requestData ) formik.setFieldValue("platform", requestData.platform);
        }
        // eslint-disable-next-line
    }, [requestData]);

    // This hook runs when the platform changes. Its used to update the validation schemas 
    // used with the form validation. It uses the PlatformSettings due_date var to set the 
    // proper schema for the due date and then the hide_mode property to set the mode schema
    React.useEffect(() => {
        let tmpValidationSchema = CommonValidationSchema;
        if( PlatformSettings[formik.values.platform].due_date === null ) tmpValidationSchema.fields.dueDate = NullDueDateSchema;
        if('hide_mode' in PlatformSettings[formik.values.platform].request) tmpValidationSchema.fields.mode = ModeAllSchema;
        setValidationSchema(tmpValidationSchema);
    }, [formik.values.platform]);

    // Hook used to dynamicaly update the schema validation used on the Other Manufacturer
    // form field.
    React.useEffect(() => {
        if(formik.values.manufacturer) {
            if( formik.values.manufacturer === "Other" ){
                let newSchema = validationSchema;
                newSchema.fields.other = RequiredOtherManufactureSchema;
                setValidationSchema(newSchema)
            } else {
                let newSchema = validationSchema;
                newSchema.fields.other = OtherManufactureSchema;
                setValidationSchema(newSchema)
            }
        }
        // eslint-disable-next-line
    }, [formik.values.manufacturer]);
    
    // This Hook is used to reset the form values to their defaults when the request dialog is closed.
    React.useEffect(() => {
        if( !reqDialogOpen ) formik.resetForm(formikDefaults);
        // eslint-disable-next-line
    }, [reqDialogOpen])

    return (
        <Dialog
            open={reqDialogOpen}
            onClose={() => setReqDialogOpen(false)}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle>Fixture Request Form</DialogTitle>
            <DialogContent dividers>
                <Grid container justifyContent="center" className={styles.root}>
                    {/* Request Form */}
                    <Grid item className={styles.left}>
                        <form onSubmit={formik.handleSubmit}>
                            <Typography variant='body1' style={{marginTop: 20, marginRight: 70}}>
                                Please complete all the fields in the form located below. Helper 
                                instructions will appear to the right of the form as you click on each field.
                            </Typography>

                            <Typography gutterBottom variant='body1' style={{marginTop: 20, marginRight: 70}}>
                                Due to safety concerns, we are unable to fulfill requests for Class 3B or Class 4 
                                laser products. This additionally includes laser products that lack clear Class markings, 
                                as well as for any pyrotechnic or rigging winch products. For more information
                                <strong>
                                    <Link href='/help?request' variant='body1' style={{color: 'blue', cursor: 'pointer'}}> click here.</Link>
                                </strong>
                            </Typography>

                            
                            {   // Additional Text for Eos
                                PlatformSettings[formik.values.platform].request.additional_text && PlatformSettings[formik.values.platform].request.additional_text ?
                                    <Typography gutterBottom variant='body1' style={{marginTop: 20, marginRight: 70}}>
                                        {
                                            formik.values.platform === "Eos v3" ?
                                                <>
                                                    Note: If you are in need of a fixture profile immediately, then you will either need 
                                                    to build your own profile for the fixture, or if you are running Eos v3.2.4 or greater 
                                                    then you can do a
                                                    <strong>
                                                        <Link href='https://support.etcconnect.com/ETC/Consoles/Eos_Family/Software_and_Programming/GDTF' variant='body1' style={{color: 'blue', cursor: 'pointer'}}> GDTF Import.</Link>
                                                    </strong>
                                                </>
                                            :
                                                <>
                                                    Note: If you are in need of a fixture profile immediately, then you will need 
                                                    to build your own profile for the fixture.
                                                </>
                                        }
                                    </Typography>
                                :
                                    null
                            }

                            {/* CustomerSection */}
                            <CustomerSection formik={formik} setSection={setSection} />
                            
                            {/* Platform */}
                            <Platform formik={formik} setSection={setSection}/>

                            {/* Form Fields */}
                            <FixtureSection formik={formik} setSection={setSection} requestData={requestData}/>
                            <DocUploader formik={formik} setSection={setSection}/>
                            <CommentsDelivery formik={formik} setSection={setSection}/>
                            <ReCaptch formik={formik} recaptchaRef={recaptchaRef}/>

                            {/* Submit button */}
                            <Grid container item xs justifyContent="center" style={{paddingTop: 20}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </form>
                    </Grid>

                    {/* Helper Section */}
                    <Grid item className={styles.right}>
                        <FieldHelper section={section} platform={formik.values.platform}/>
                    </Grid>

                    {/* Successs Dialog */}
                    <ConfirmationDialog 
                        openDialog={openDialog} 
                        setOpenDialog={setOpenDialog} 
                        confirmation={confirmation} 
                        formik={formik} 
                        init={formikDefaults} 
                        recaptchaRef={recaptchaRef} 
                        setReqDialogOpen={setReqDialogOpen}
                    />

                    {/* Progress */}
                    <Modal open={submitProgress}>
                        <CircularProgress color="secondary" style={{position: 'absolute', left: '50%', top: '50%'}}/>
                    </Modal>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

// Request Page Formik Defaults Loader
export default function RequestDialog(props){
    return props.requestData ? 
            <DownloadsContextProvider>
                <RDialog {...props}/> 
            </DownloadsContextProvider>
        : 
            null
};
