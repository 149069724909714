import React from 'react';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import theme from '../../../../AppTheme';

// Common DMX Channel Template
const DMXChannel = ({data}) => (
    <div style={{padding: 5}}>
        <Grid container item xs>
            <Typography variant='body2' style={{margin: "0 10px 0 10px"}}>
                <strong>{`Ch ${data.channel}`}</strong>
            </Typography>
            <Typography variant='body2' noWrap>
                {data.function}
            </Typography>
        </Grid>
        <Divider style={{marginTop: 5, backgroundColor: theme.palette.etc.main}}/>
    </div>
)

export default function ProtocolInfo({mode}) {
    // DMX Channel Column State
    const [leftColumn, setLeftColumn] = React.useState();
    const [rightColumn, setRightColumn] = React.useState();

    // Hook used to split the DMX Channels into 2 columns
    React.useEffect(() => {
        // Calculate the number of items that will be in the left column
        const left = Math.ceil( mode.dmx.length / 2 )

        // Create an array of keys to expect for the left column
        const lColKeys = [...Array(left).keys()];

        let lArray = [];
        let rArray = [];
        for(const channel in mode.dmx){
            if( lColKeys.includes( Number(channel) ) ) lArray.push( mode.dmx[channel] )
            else rArray.push( mode.dmx[channel] )
        }

        // Set the column items
        setLeftColumn(lArray);
        setRightColumn(rArray);
    }, [mode])

    return (
        <Grid container>
            <Grid item xs>
                <Box
                    borderRadius={theme.palette.browse.reportCard.borderRadius}
                    style={{backgroundColor: theme.palette.browse.reportCard.backgroundColor}}
                    padding={2}
                >
                    <Grid container>
                        {/* Header */}
                        <Grid container justifyContent="center">
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>{`DMX Footprint (${mode.footprint} ch)`}</strong>
                            </Typography>
                        </Grid>
                        {/* DMX Data */}
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {
                                    leftColumn && leftColumn.map((d, idx) =>(
                                        <DMXChannel key={idx} data={d}/>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    rightColumn && rightColumn.map((d, idx) =>(
                                        <DMXChannel key={idx} data={d}/>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
