import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: "25px 0px 0px 25px"
    },
    listItem: {
        padding: "10px 0px 10px 0px"
    }
})

const numberedItems = [
    "Log into the Fixture Library Hub using your ETC One credentials.",
    "Click on the profile picture located at the top right of the site and select the Account option."
]

export default function AccountManagement(){
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container direction='column'>
                <Typography variant='h5' gutterBottom>
                    Account Management
                </Typography>
                <Typography variant='body1'>
                    To manage preferences associated with your Fixture Library Hub account:
                </Typography>
                <ol>
                    {
                        numberedItems.map((item, idx) => (
                            <li key={idx} className={classes.listItem}>
                                <Typography variant='body1'>{item}</Typography>
                            </li>
                        ))
                    }
                </ol>
                <Typography variant='body1'>
                    The account page allows you to adjust your preferred console platform and view any requests you 
                    have submitted.  The preferred console platform setting is used to pre-populate the platform on 
                    the request page form and automatically selects that platform on the browse page.
                </Typography>
            </Grid>
        </div>
    )
}