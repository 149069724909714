import React from 'react';
import { Snackbar } from '@material-ui/core'; 
import MuiAlert from '@material-ui/lab/Alert';
// import Slide from '@material-ui/core/Slide';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const StatusHandlerDefault = {
    status: false, 
    message: "", 
    severity: "success"
};

export function HandleStatus({status, severity, message}) {
// export function HandleStatus({statusHandler}) {
// export function HandleStatus(props) {
    // const [compStatus, setCompStatus] = React.useState({status, message, severity});

    // const [displayStatus, setDisplayStatus] = React.useState(false);

    // console.log(props)
    // const {status, severity, message, setStatusHandler} = props;
    
    // React.useEffect(() => {
    //     console.log("Handle Status : ", compStatus)
    // })

    /* React.useEffect(() => {
        
        if( statusHandler.status ) {
            console.log("Status :", statusHandler)

            // Display the snackbar
            setDisplayStatus(statusHandler.status);

            // Hide the snackbar in the allotted time
            setTimeout(() => {
                setDisplayStatus(false);
            }, 5000)
        }
    },[statusHandler]) */

    // function close(evt, reason){
    //     console.log("Closing evt :", evt)
    //     console.log("Closing reason :", reason)
    // }

    return (
        <div>
            <Snackbar 
                open={status} 
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                disableWindowBlurListener
                // autoHideDuration={5000}
                // onClose={(evt, reason) => close(evt, reason)}
            >
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
        </div>
    )
}
