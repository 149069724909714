import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import HogModeInfo from "./sections/HogModelnfo";
import ProtocolInfo from "./sections/ProtocolInfo";
import RevisionInfo from "./sections/RevisionInfo";

const useStyles = makeStyles((theme) => ({
    header: {
        margin: '0px 0px 15px 15px'
    },
    container: {
        padding: '0px 20px 0px 20px',
        maxHeight: theme.palette.browse.reportCard.height,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: theme.palette.etc.main,
            outline: '0px solid slategrey'
        },
    }
}));

export default function HogReportCard({ mode }) {
    const styles = useStyles();
    // If selectedType then we show product report card. If not, then the loading card is shown.
    return (        
        <div className={styles.container}>
            <Grid container direction="column">
                {/* Info Section */}
                <Grid item xs style={{marginBottom: 10}}>
                    <HogModeInfo mode={mode}/>
                </Grid>

                {/* DMX Section */}
                <Grid item xs style={{marginBottom: 10}}>
                    <ProtocolInfo mode={mode}/>
                </Grid>

                {/* Revision/History Section */}
                <Grid item xs>
                    <RevisionInfo mode={mode}/>
                </Grid>

                {/* <Grid item xs>
                    <ModeActions auth={auth} mode={mode}/>
                </Grid> */}
            </Grid>
        </div>
    )
}