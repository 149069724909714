import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Avatar } from "@material-ui/core";
import { Link } from 'react-router-dom';
import ETCLogo from '../../assets/logo-header.png';
import { UserContext } from '../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
    links: {
        color: "whitesmoke",
        fontSize: "11pt",
        fontFamily: "Frutiger LT Bold",
        paddingLeft: 20,
        paddingRight: 20
    },
    loginButton: {
        backgroundColor: "black", 
        color: "white",
        minWidth: 225,
        boxShadow: `0 0 5px 2px ${theme.palette.etc.hover}`,
        padding: 0
    }
}));

export default function LoggedOutNav(){
    const styles = useStyles();
    const userCtx = React.useContext(UserContext);

    return (
        <Grid item container xs={8} alignItems="center">
            <Grid item xs={10}>
                {/* <Button target="_blank" href="https://etcconnect.com" className={styles.links} style={{paddingLeft: 40}}>
                    ETCConnect
                </Button> */}
                <Button component={Link} to="/" className={styles.links}>
                    Fixture Library Hub
                </Button>
                {/* <Button component={Link} to="/request" className={styles.links}>
                    Request
                </Button> */}
                <Button component={Link} to="/help"className={styles.links}>
                    Help
                </Button>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
                <Grid container item justifyContent="center">
                    <Button
                        variant="contained"
                        size={"small"}
                        className={styles.loginButton}
                        startIcon={
                            <Avatar
                                variant="square" 
                                src={ETCLogo}
                            />
                        }
                        onClick={() => userCtx.loginWithRedirect(window.location.pathname)}
                        style={{marginRight: 70}}
                    >
                        Login with ETC One
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
