import React from 'react';
import { TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import theme from '../../AppTheme';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Custom TextField
const CssTextField = withStyles({
    root: {
        '& label': {
            color: "white",
            fontSize: theme.palette.browse.fields.fontSize,
        },
        '& label.Mui-focused': {
            color: "white",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.etc.main,
        },
        '& .MuiOutlinedInput-root': {
            color: "white",
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.etc.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.etc.main,
            }
        },
    }
})(TextField);


// Styles
const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: "red !important",
    },
    option: {
        backgroundColor: theme.palette.background.inner,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.etc.main
        }
    },
    paper: {
        backgroundColor: theme.palette.background.inner,
    },
    clearIndicatorDirty: {
        color: "gray",
        "&:hover": {
            color: "gray"
        },
        "&:active": {
            color: "gray"
        },
    },
    popupIndicator: {
        color: "gray"
    },
    input: {
        backgroundColor: theme.palette.background.inner,
        whiteSpace: "nowrap",
        // overflow: "hidden",

        '& .MuiOutlinedInput-root': {
            fontSize: theme.palette.browse.fields.fontSize,
            '& fieldset': {
                borderColor: theme.palette.browse.fields.borderColor,
                borderRadius: theme.palette.browse.fields.borderRadius
            }
        },
    }
}));


const ManufacturerSearchBar = ({
    manufacturers,
    selectedManufacturers,
    setSelectedManufacturers,
    disabled,
    setDisplayByManufacturerInList
}) => {
    const styles = useStyles();

    // Handles rendering the autocomplete input
    const autocompleteInput = (params) => {
        return <CssTextField {...params}
            variant="outlined"
            label="Filter by Manufacturer"
            className={styles.input}
        />
    }

    // Handle when all manufacturers have been cleared
    const handleChipDeleteAdornment = (manSelection) => {
        setSelectedManufacturers(manSelection);
        setDisplayByManufacturerInList(true);
    }

    // Handles rendering the manufacturer chips
    const manufacturerChips = (chipArray) => {
        return chipArray.map((manufacturer, idx) =>
            <Chip
                style={{
                    margin: "1px 3px 1px 3px",
                    backgroundColor: Object.keys(manufacturer.color)[0],
                    fontWeight: 900,
                    color: manufacturer.color[Object.keys(manufacturer.color)[0]],
                    fontSize: theme.palette.browse.fields.manufacturerChip
                }}
                variant={'outlined'}
                // label={manufacturer.title + " (" + manufacturer.productCount + ")"}
                label={manufacturer.title}
                key={idx}
                size={'small'}
                onDelete={() => handleChipDeleteAdornment(selectedManufacturers.filter(m => m.title !== manufacturer.title))}
            />
    )}

    return (
        <div style={{margin: "15px 15px 0px 15px"}}>
            <Autocomplete
                classes={{
                    root: styles.root, 
                    option: styles.option, 
                    paper: styles.paper, 
                    clearIndicatorDirty: styles.clearIndicatorDirty, 
                    popupIndicator: styles.popupIndicator
                }}
                value={selectedManufacturers}
                multiple
                size={'small'}
                disabled={disabled}
                filterSelectedOptions
                disableClearable={true}
                limitTags={2}
                options={manufacturers}
                // getOptionLabel={(m) => m.title + " (" + m.productCount + ")"}
                getOptionLabel={(m) => m.title}
                renderInput={autocompleteInput}
                renderTags={manufacturerChips}
                onChange={(evt, newValue) => setSelectedManufacturers(newValue)}
            />
        </div>
    )
}

export default ManufacturerSearchBar;
