import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/ETC_4c_rev.png';
import { Box, Divider, Typography } from '@material-ui/core';
import careersImg from '../../assets/footer_icons/jobs.png';
import contactUs from '../../assets/footer_icons/contact-us.png';
import legacyImg from '../../assets/footer_icons/legacy.png';
import patentImg from '../../assets/footer_icons/trademarks-copyrights.png';
import privacyImg from '../../assets/footer_icons/privacy-policy.png';
import { Facebook, Twitter, YouTube, LinkedIn, Instagram, Blog } from '../../icons/icons'

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        // bottom: 0,
        width: "100%",
        minWidth: "1280px"
    },
    footer: {
        marginTop: 40,
        backgroundColor: "black",
        fontFamily: "Frutiger LT Bold",
        fontSize: "9pt",
        color: "white",
    },
    version: {
        backgroundColor: "black",
        fontFamily: "Frutiger LT Bold",
        fontSize: "8pt",
        color: "#333333", 
        padding: "0 3px 3px 0"
    },
    container: {
        padding: "25px 15px 0px 15px",
        width: "75%"
    },
    divider: {
        backgroundColor: "#666666",
        margin: "25px 25px 15px 25px"
    },
    bottom: {
        padding: "0 0 25px 0"
    },
    logo: {
        height: 110,
        paddingLeft: 20
    },
    section: {
        width: 160,
    },
    text: {
        padding: "10px 10px 0 10px",
        textAlign: "center",
        textTransform: 'uppercase'
    },
    socialContainer: {
        fontFamily: "Frutiger LT, Arial",
        fontSize: "18pt",
        padding: "0px 60px 0px 0px"
    }
}));

// Footer Links
const footerItems = [
    {
        title: "Careers",
        image: careersImg,
        href: "https://www.etcconnect.com/Careers/",
        alt: "Careers Image",
        mt: 0,
    },
    {
        title: "Contact Us",
        image: contactUs,
        href: "https://www.etcconnect.com/Contact-Us.aspx",
        alt: "Contact Us Image",
        mt: "2px",
        imagePadding: 1
    },
    {
        title: "Legacy Products",
        image: legacyImg,
        href: "https://www.etcconnect.com/Products/Legacy/",
        alt: "Legacy Products Image",
        mt: "7px",
    },
    {
        title: "Patents & Trademarks",
        image: patentImg,
        href: "https://www.etcconnect.com/IP",
        alt: "Patents and Trademarks Image",
        mt: "5px",
        imageSize: ["30px", "24px"]
    },
    {
        title: "Privacy, Cookie, Terms of use, And Acceptable use Policies",
        image: privacyImg,
        href: "https://www.etcconnect.com/Privacy-Policy-Terms-of-Use-and-Acceptable-Use.aspx",
        alt: "Privacy Image Image",
        mt: "8px",
    },
    {
        title: "California Transparency Act & United Kingdom Modern Slavery Act 2015",
        image: legacyImg,
        href: "https://www.etcconnect.com/About/California-Supply-Chain-Transparency-Act.aspx",
        alt: "Transparency Image",
        mt: "7px",
    }
]

// Social Media Icons
const socialMedia = [
    {
        name: "facebook",
        icon: <Facebook style={{height: 15, width: 15}}/>
    },
    {
        name: "twitter",
        icon: <Twitter style={{height: 15, width: 15}}/>
    },
    {
        name: "youtube",
        icon: <YouTube style={{height: 15, width: 15}}/>
    },
    {
        name: "linkedin",
        icon: <LinkedIn style={{height: 15, width: 15}}/>
    },
    {
        name: "instagram",
        icon: <Instagram style={{height: 15, width: 15}}/>
    },
    {
        name: "blog",
        icon: <Blog style={{height: 15, width: 15}}/>
    }
]

export default function Footer(){
    const styles = useStyles();
    
    return (
        <Box className={styles.root}>
            {/* Footer */}
            <Box display="flex" justifyContent="center" className={styles.footer}>
                <Box display="flex" flexDirection="column" className={styles.container}>
                    {/* Top */}
                    <Box display="flex" flexDirection="row" justifyContent="center" mt={1}>
                        {
                            footerItems.map((item, idx) => (
                                <a key={idx} href={item.href} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: "white"}}>
                                    <Box display="flex" flexDirection="column" alignItems="center" className={styles.section}>
                                        <Box 
                                            display="flex"
                                            p={item.imagePadding ? item.imagePadding : 0} 
                                        >
                                            <img 
                                                height={item.imageSize ? item.imageSize[0] : null} 
                                                width={item.imageSize ? item.imageSize[1] : null} 
                                                src={item.image} 
                                                alt={item.alt}
                                            />
                                        </Box>
                                        <Box display="flex" className={styles.text} mt={item.mt}>
                                            {item.title}
                                        </Box>
                                    </Box>
                                </a>
                            ))
                        }
                    </Box>

                    <Divider className={styles.divider} light orientation='horizontal'/>

                    {/* Bottom */}
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className={styles.bottom}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <a href="https://etcconnect.com" target="_blank" rel="noopener noreferrer">
                                <img src={logo} className={styles.logo} alt="ETC Logo"/>
                            </a>
                            <Typography variant='body1' style={{padding: "0px 0px 10px 45px", color: "#525252"}}>
                                visual environment technologies
                            </Typography>
                        </Box>
                        
                        <a href="https://www.etcconnect.com/Get-connected.aspx" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>
                            <Box display="flex" flexDirection="column" className={styles.socialContainer}>
                                <Box display="flex" justifyContent="center">
                                    follow us
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    {
                                        socialMedia.map((social, idx) => (
                                            <Box key={idx} style={{padding: 5}}>
                                                {social.icon}
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        </a>
                    </Box>
                </Box>
            </Box>

            {/* Version */}
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" className={styles.version}>
                v {process.env.REACT_APP_VERSION}
            </Box>
        </Box>
    )
}