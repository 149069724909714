// An object of all current platforms and what functionality they can perform on the site.
// preferable : Allows the platform to be displayed in the 'Preferred Platforms' list in the users account page.
// browsable : Platforms with this property set to true are available in the 'Platforms' list on the browse page.
// requestable : Plaforms with this property set to true are available in the 'Platforms' list on the request page.
// min_date : This is the minimum amount of timeframe (in days) needed preceeding the selected date in the 'Needs by Date' of the request page.
// due_date : This is the date which is set (in days). Cannont be less than the min_date

const Mosaic = {
    preferable: false,
    browsable: true,
    requestable: true,
    min_date: 14,
    due_date: 14,
    supported_models: ["Mosaic"],
    request: {
        platform_info: {
            header: "This platform encompasses the following models: ",
            help: "For more specific instructions on how to install fixture profiles on the Mosaic platform product please refer to the “Fixture Library” help topic in the Mosaic help manual.",
            compatibility: ""
        }
    },
    browse: {
        downloadCtx: "Mosaic",
        description: {
            header: "This platform encompasses the following models: ",
            help: "For more specific instructions on how to install fixture profiles on the Mosaic platform product please refer to the “Fixture Library” help topic in the Mosaic help manual.",
            notes: ""
        }
    }
}

module.exports = { Mosaic }