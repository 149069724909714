// An object of all current platforms and what functionality they can perform on the site.
// preferable : Allows the platform to be displayed in the 'Preferred Platforms' list in the users account page.
// browsable : Platforms with this property set to true are available in the 'Platforms' list on the browse page.
// requestable : Plaforms with this property set to true are available in the 'Platforms' list on the request page.
// min_date : This is the minimum amount of timeframe (in days) needed preceeding the selected date in the 'Needs by Date' of the request page.
// due_date : This is the date which is set (in days). Cannont be less than the min_date

const Hog4v3 = {
    preferable: true,
    browsable: true,
    requestable: true,
    min_date: 14,
    due_date: 14,
    supported_models: ["Hog 4-18", "Hog 4", "Full Boar 4", "Road Hog 4", "Road Hog 4-21", "HedgeHog 4 (all models)", "Hog 4 PC", "Rack Hog 4", "HPU"],
    request: {
        platform_info: {
            header: "This platform encompasses the following models: ",
            help: "For instructions on how to install Hog platform profiles on a console please refer to the “Fixture Library” help topic in the Hog OS help manual.",
            compatibility: "Hog 4 v3 profiles are compatible with Hog 4 OS v3.14.0 or greater."
        }
    },
    browse: {
        downloadCtx: "Hog 4 v3",
        description: {
            header: "The Fixture Profiles located in the browse section of the Fixture Library Hub under the Hog 4 v3 platform are compatible with the following consoles running Hog 4 OS v3.9.0 and greater:",
            help: "For instructions on how to install fixture profiles on a Hog 4 platform console please refer to the “Fixture Library” help topic in the Hog 4 OS help manual.",
            notes: "The full fixture library download is only compatible with consoles running Hog 4 OS v3.14.0 and greater."
        }
    }
}

const Hog4v4 = {
    preferable: true,
    browsable: true,
    requestable: true,
    min_date: 14,
    due_date: 14,
    supported_models: ["Hog 4-18", "Hog 4", "Full Boar 4", "Road Hog 4", "Road Hog 4-21", "HedgeHog 4 (all models)", "Hog 4 PC", "Rack Hog 4", "HPU"],
    request: {
        platform_info: {
            header: "This platform encompasses the following models: ",
            help: "For instructions on how to install Hog platform profiles on a console please refer to the “Fixture Library” help topic in the Hog OS help manual.",
            compatibility: "Hog 4 v3 profiles are compatible with Hog 4 OS v3.14.0 or greater."
        }
    },
    browse: {
        downloadCtx: "Hog 4 v4",
        description: {
            header: "The Fixture Profiles located in the browse section of the Fixture Library Hub under the Hog 4 v4 platform are compatible with the following consoles running Hog 4 OS v4.0.0 and greater:",
            help: "For instructions on how to install fixture profiles on a Hog 4 platform console please refer to the “Fixture Library” help topic in the Hog 4 OS help manual.",
            notes: "The full fixture library download is only compatible with consoles running Hog 4 OS v4.0.0 and greater."
        }
    }
}

module.exports = { Hog4v3, Hog4v4 }