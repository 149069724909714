import React from 'react';
import { Box } from "@material-ui/core";
import HogReportCard from './HogReportCard';
import RequestReportCard from './RequestReportCard';
import CompoundReportCard from './CompoundReportCard';
import CarallonReportCard from './CarallonReportCard';

export default function ReportCardLoader({ tileSelected, schema }) {
    tileSelected.schema = schema

    const isMode = tileSelected && (tileSelected.mode && !tileSelected.key && !tileSelected.parts);
    const isCompound = tileSelected && (tileSelected.parts && tileSelected.mode && !tileSelected.part && !tileSelected.key);
    const isRequest = tileSelected && (tileSelected.key && tileSelected.mode && !tileSelected.parts);
    const isHogSchema = schema === 'hogv4' || schema === 'hogv5';

    return (
        <Box p={1}>
            {/* Mode */}
            {
                isMode && (isHogSchema ? 
                            <HogReportCard mode={tileSelected} /> 
                            : 
                            <CarallonReportCard mode={tileSelected} />
                            )
            }

            {/* Compound */}
            {
                isCompound && <CompoundReportCard compound={tileSelected} />
            }

            {/* Request */}
            {
                isRequest && <RequestReportCard request={tileSelected} />
            }
        </Box>
    )
}
