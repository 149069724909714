import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import TileImage from './TileImage';

const useStyles = makeStyles((theme) => ({
    tile: {
        display: "flex",
        borderRadius: theme.palette.browse.reportCard.borderRadius,
        backgroundColor: theme.palette.browse.reportCard.backgroundColor,
        padding: 10,
        margin: 10,
        maxWidth: theme.palette.browse.tile.width,
        minWidth: theme.palette.browse.tile.width,
        "&:hover": {
            cursor: "pointer"
        }
    },
    info: {
        textAlign: "left",
        padding: theme.palette.browse.tile.infoSpacing,
        fontSize: theme.palette.browse.tile.officialInfo
    },
    ratings: {
        padding: "10px 0px 0px 0px",
    }
}));

export default function ProfileTile({ item, setTileSelected, loading}) {
    const styles = useStyles();
    return(
        !loading ? 
            <Box 
                m={1}
                onClick={() => setTileSelected(item)}
                className={styles.tile}
            >
                <Grid container item direction="row" alignItems="center">
                    <Grid item>
                        <TileImage icon={item.icon} />
                    </Grid>

                    <Grid item xs zeroMinWidth>
                        <Typography noWrap variant="body1" className={styles.info}>
                            {item.mode + " mode"}
                        </Typography>
                        <Typography noWrap variant="body2" className={styles.info}>
                            {"Revision: " + item.revision}
                        </Typography>
                        <Typography noWrap variant="body2" className={styles.info}>
                            {"DMX Footprint: " + item.footprint}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        :
            <Skeleton variant='rect' height="64px" width="215px" style={{padding: 10, margin: 10}}/>
    )
}