import React from "react";
import { Grid, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FixedSizeList } from 'react-window';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../AppTheme';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.etc.hover
        },
    },
    selected: {
        backgroundColor: `${theme.palette.etc.main} !important`,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.etc.main,
            color: "white",
        }
    },
    product: {
        fontSize: theme.palette.browse.products.fontSize
    },
    manufacturer: {
        fontSize: theme.palette.browse.products.manufacturerFontSize,
        paddingLeft: 5
    },
    list: {
        backgroundColor: theme.palette.background.inner, 
        color: "white",
        '&::-webkit-scrollbar': {
            width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: theme.palette.etc.main,
            outline: '0px solid slategrey'
        }
    }
}));

const Products = ({ 
    products, 
    setSelectedProduct, 
    displayByManufacturerInList, 
    disabled, 
    setReqDialogOpen
}) => {
    const styles = useStyles();
    
    // Renders the row item
    function renderRow({ index, style, data }) {
        return (
            disabled ? 
                <Skeleton variant="text" height={80}/>   
            :
                'request' in data[index] ? 
                    <ListItem
                        classes={{selected: styles.selected, root: styles.root}}
                        button
                        divider
                        dense
                        selected={data[index].active}
                        style={{...style, ...data[index].style}}
                        key={index}
                        onClick={() => {
                            setReqDialogOpen(true)
                            setSelectedProduct(data[index])
                        }}
                    >
                        <ListItemText>
                            <Grid container item alignItems="center" wrap="nowrap">
                                <Grid item style={{paddingTop: 5, marginRight: 10}}>
                                    <AddCircleIcon style={{color: 'gray'}}/>
                                </Grid>
                                <Grid item zeroMinWidth>
                                    <Typography variant="body1" noWrap classes={{root: styles.product}}>
                                        {data[index].product}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListItem>
                :
                    <ListItem
                        classes={{selected: styles.selected, root: styles.root}}
                        button
                        divider
                        dense
                        selected={data[index].active}
                        style={{...style, ...data[index].style}}
                        key={index}
                        onClick={() => setSelectedProduct(data[index])}
                    >
                        <ListItemText>
                            <Grid container item zeroMinWidth>
                                <Typography
                                    variant="body1"
                                    noWrap
                                    classes={{root: styles.product}}
                                >
                                    {data[index].product}
                                    {
                                        displayByManufacturerInList ? 
                                            <Typography
                                                variant="caption"
                                                noWrap
                                                classes={{root: styles.manufacturer}}
                                            >
                                                {"by " + data[index].manufacturer}
                                            </Typography>
                                        : null
                                    }
                                </Typography>
                            </Grid>
                        </ListItemText>
                    </ListItem>
        );
    }

    return (
        // Don't render products until there are products to render
        products && products ?
            <FixedSizeList
                height={theme.palette.browse.products.listHeight}
                itemSize={46}
                itemData={products}
                itemCount={products.length}
                className={styles.list}
            >
                {renderRow}
            </FixedSizeList>
        :
            null
    );
};

export default Products;
