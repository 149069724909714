import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../AppTheme';

const HubTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0px`
            },
            '&:hover fieldset': {
                borderColor: theme.palette.etc.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.etc.main,
            },
        }
    },
})(TextField);

export default HubTextField;