const { Hog4v3, Hog4v4 } = require('./platforms/Hog')
const { CSET } = require('./platforms/CSET');
const { Cobalt } = require('./platforms/Cobalt');
const { Eosv2, Eosv3 } = require('./platforms/Eos');
const { Mosaic } = require('./platforms/Mosaic');
const { Paradigmv2, Paradigmv3, Paradigmv4, Paradigmv5 } = require('./platforms/Paradigm');

// This is the default use where the platform is not used.
const PlatformDefault = "Eos v3";

// These are the settings for each platform
const PlatformSettings = {
    "ColorSource / EchoTouch": CSET,
    "Cobalt": Cobalt,
    "Eos v2": Eosv2,
    "Eos v3": Eosv3,
    "Hog 4 v3": Hog4v3,
    "Hog 4 v4": Hog4v4,
    "Mosaic": Mosaic,
    "Paradigm v2": Paradigmv2,
    "Paradigm v3": Paradigmv3,
    "Paradigm v4": Paradigmv4,
    "Paradigm v5": Paradigmv5
}

module.exports = { PlatformDefault, PlatformSettings }