import React from 'react';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import { PlatformsContext } from '../../contexts/PlatformsContext';
import HubTextField from '../common/HubTextField';

export default function Platform({ formik, setSection }) {
    const platforms = React.useContext(PlatformsContext);

    // Function to handle setting the formik platform value
    function handlePlatformSelection(e){
        if(e.target.value) formik.setFieldValue("platform", e.target.value);
    }

    return (
        platforms && platforms ?
            <Grid container item xs direction="column">
                <Typography variant="body1" style={{margin: "20px 0px 10px 0px"}}><strong>Console Platform</strong></Typography>
                <Grid container item xs>
                    <Grid container item xs={4} style={{paddingRight: 15}} alignItems="center" justifyContent="flex-end">
                        <Typography variant="body1">Platform</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <HubTextField
                            variant="outlined"
                            // select   // Uncomment this to see the dropdown arrow
                            value={formik.values.platform}
                            fullWidth
                            disabled
                            size={"small"}
                            onClick={handlePlatformSelection}
                            InputProps={{
                                onMouseOver: (event) => setSection(formik.values.platform),
                                onBlur: () => setSection(null)
                            }}
                        >
                            {
                                platforms.map((p, idx) => {
                                    if(p.requestDisabled) return null;
                                    else {
                                        return (
                                            <MenuItem 
                                                key={idx} 
                                                value={p.name}
                                            >
                                                {p.name}
                                            </MenuItem>
                                        )
                                    }
                                })
                            }
                        </HubTextField>
                    </Grid>
                </Grid>
            </Grid>
        : 
            null
    )
}