import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Calendar } from '../../icons/icons';
import { PlatformSettings } from '../../AppSettings';

const HubKeyboardDatePicker = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0px`,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.etc.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.etc.main,
            },
            '&.MuiOutlinedInput-adornedEnd': {
                padding: 0
            }
        }
    },
}))(KeyboardDatePicker);

export default function HubDatePicker({ formik, setSection }) {
    const [minDate, setMinDate] = React.useState(null);
    const [hidden, setHidden] = React.useState(false);

    // This hook runs when the platform is changed on the request page. Its used
    // to update the minDate state prop
    React.useEffect(() => {

        // Set the min date timeframe. Get the min date timeframe from the AppSettings file.
        let date = new Date();
        date.setDate(date.getDate() + PlatformSettings[formik.values.platform].min_date);
        setMinDate(date);

        // This bit of logic sets the due date value according to its setting in the AppSettings file
        if( PlatformSettings[formik.values.platform].due_date ) {
            let date = new Date();
            date.setDate(date.getDate() + PlatformSettings[formik.values.platform].due_date);
            formik.setFieldValue("dueDate", date);
        } else {
            formik.setFieldValue("dueDate", PlatformSettings[formik.values.platform].due_date);
        }

        // Hides the due date field
        if('hide_duedate' in PlatformSettings[formik.values.platform].request) setHidden(true);
        else setHidden(false);

        // eslint-disable-next-line
    }, [formik.values.platform]);

    return (
        hidden ? null :
            <div>
                <Grid container item xs style={{paddingTop: 10}}>
                    <Grid container item xs={4} justifyContent="flex-end" alignContent='center' style={{paddingRight: "15px"}}>
                        <Typography variant="body1">Need By Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            minDate ? 
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <HubKeyboardDatePicker
                                        fullWidth
                                        size={'small'}
                                        disableToolbar
                                        disablePast
                                        format="MMM dd, yyyy"
                                        variant="inline"
                                        inputVariant="outlined"
                                        minDate={minDate}
                                        value={formik.values.dueDate}
                                        onChange={(e) => formik.setFieldValue("dueDate", e)}
                                        onMouseOver={() => setSection("dueDate")}
                                        onFocus={() => setSection("dueDate")}
                                        onBlur={() => setSection(null)}
                                        keyboardIcon={<Calendar/>}
                                    />
                                </MuiPickersUtilsProvider>
                            :
                                null
                        }
                    </Grid>
                </Grid>
            </div>
    )
}
