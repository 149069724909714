import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
// import backgroundImage from "./assets/maintenance.webp";
import backgroundImage from "./assets/maintenance_bkg.jpg";
import whtETCLogo from "./assets/ETC_4c_rev.png";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    mainMsg: {
        color: "white",
        fontSize: "3rem",
        fontWeight: "bold",
        textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
        textAlign: "center",
        // backgroundColor: "red"
    },
    subMsg: {
        color: "white",
        fontSize: "2rem",
        fontWeight: "bold",
        textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
        marginTop: "30px",
        width: "50%",
        textAlign: "center",
        // backgroundColor: "red"
    },
    img: {
        height: "200px"
    }
}));

export default function Maintenance() {
    const styles = useStyles();
    return (
        <Box className={styles.container}
            display="flex" 
            flexDirection="column"
            height="100vh" width="100%" 
            justifyContent="center" alignItems="center" 
        >
            {/* Text */}
            <Box sx={{ 
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '300px',
            }}>
            <Box sx={{
                position: 'absolute',
                width: '75%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.5,
                zIndex: 1,
                borderRadius: 15
            }}/>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{zIndex: 2}}>
                    <Box className={styles.mainMsg}>The Fixture Library Hub Band is taking a setbreak</Box>
                    <Box className={styles.subMsg}>
                        Sorry for the inconvienence. The Fixture Library Hub is undergoing some routine server 
                        maintenance. The site will be back online/stage shortly.
                    </Box>
                </Box>
            </Box>

            {/* ETC Logo */}
            <Box sx={{ 
                position: 'absolute',
                bottom: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '20%',
                height: '200px',
            }}>
            <Box sx={{
                position: 'absolute',
                width: '75%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.5,
                zIndex: 1,
                borderRadius: 15
            }}/>
                <Box display="flex" flexDirection="column" sx={{zIndex: 2}}>
                    <img className={styles.img} src={whtETCLogo} alt="ETC Logo"/>
                </Box>
            </Box>
        </Box>
    );
}
