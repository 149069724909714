import React, { createContext, useState, useEffect } from 'react';
import { useLRAuth } from "loginradius-react";
import { PlatformDefault } from '../AppSettings';
import { getUsersPrimaryEmailAddress } from '../config/helperFuns';

// User context
const UserContext = createContext();

// User Context Provider
const UserContextProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [preferredPlatform, setPreferredPlatform] = useState(PlatformDefault);
    const { isAuthenticated, user, isLoading, logout, loginWithRedirect } = useLRAuth();

    // Update the users preferred platorm, memoized
    const updatePlatform = React.useCallback((platform) => {
        setPreferredPlatform(platform);
    }, []);

    // Hook used to set the user data once the login radius component has finished loading
    useEffect(() => {
        // Fetches the user data from the API-Server
        async function getUser(userId){
            const fetchRtn = await fetch(
                window.REACT_APP_API_SERVER_ADDRESS + `/api/user/${userId}?option=preferences`
            );
            return await fetchRtn.json();
        }

        // Creates the user info from the API-Server
        async function createUser(userData){
            // Get the users primary email address
            const primaryEmail = getUsersPrimaryEmailAddress(userData.Email);

            // Send the user data to the API server
            const fetchRtn = await fetch(
                window.REACT_APP_API_SERVER_ADDRESS + `/api/user/${userData.ID}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        firstName: userData.FirstName,
                        lastName: userData.LastName,
                        email: primaryEmail,
                        created: userData.CreatedDate,
                        lastLogin: userData.LastLoginDate,
                        provider: userData.Provider,
                        country: userData.LocalCountry,
                        displayName: userData.UserName,
                        preferredPlatform: PlatformDefault
                    }),
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            );

            const newUserData = await fetchRtn.json();

            return newUserData;
        }
        
        // Wait until the login radius hook has loaded before setting the user data
        if(!isLoading){
            // Handle authenticated users
            if(isAuthenticated){
                // Query the API-Server for the user 
                getUser(user.ID)
                .then(async (data) => {
                    // If the user does not exist then create them
                    if( !data.user ) createUser(user);
                    else setPreferredPlatform(data.user.preferences.preferredPlatform);
                    
                    // Set the user data object
                    setUserData({...user});
                })
                .catch(error => {
                    console.log(error);
                });

            } else {
                // Handle unauthenticated defaults
                setUserData({...user});
            }
        }        
    },[isAuthenticated, user, isLoading])


    // Memoize the full user context values
    const userContextValue = React.useMemo(() => ({
        user: userData,
        preferredPlatform,
        updatePlatform,
        isAuthenticated,
        logout,
        loginWithRedirect
    }), [userData, preferredPlatform, updatePlatform, isAuthenticated, logout, loginWithRedirect])

    return (
        <UserContext.Provider value={userContextValue}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserContextProvider  }