import React from 'react';
import { Grid, List, ListItem, ListItemText, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

// Help Topics Components
import Browsing from './topics/Browsing';
import Downloading from './topics/Downloading';
import Requesting from './topics/Requesting';
import EtcOne from './topics/EtcOne';
import AccountManagement from './topics/AccountMgmt';

const useStyles = makeStyles((theme) => ({
    root: {
        // height: "90vh",  // retains the full height of the page
        // margin: "30px"
        margin: "0px 15px 0px 15px"
    },
    listRoot: {
        "&:hover": {
            backgroundColor: theme.palette.etc.hover,
            cursor: "pointer"
        }
    },
    listSelected: {
        backgroundColor: `${theme.palette.etc.main} !important`,
        "&:hover": {
            backgroundColor: theme.palette.etc.main
        }
    },
    paper: {
        backgroundColor: theme.palette.background.inner, 
        color: "white",
        borderRadius: 0
    },
    left: {
        maxWidth: 275,
        minWidth: 250,
        width: "22%"
    },
    right: {
        width: "53%"
    }
}));

const helpTopics = {
    browse: {
        name: "Browsing for Profiles",
        topic: "browse",
        comp: <Browsing />
    },
    download: {
        name: "Downloading Profiles",
        topic: "download",
        comp: <Downloading />
    },
    request: {
        name: "Requesting Profiles",
        topic: "request",
        comp: <Requesting />
    },
    etcone: {
        name: "What is ETC One?",
        topic: "etcone",
        comp: <EtcOne />
    },
    account: {
        name: "Account Management",
        topic: "account",
        comp: <AccountManagement />
    }
}

export default function Help() {
    const classes = useStyles();
    const [selectedTopic, setSelectedTopic] = React.useState(helpTopics['browse']);

    // Effect used to set the section if needed. The section is set by appending
    // a search which is the same name as the help topics topic property.
    React.useEffect(() => {
        if(window.location.search) {
            const search = window.location.search;
            setSelectedTopic(helpTopics[search.replace("?", "")])
        }
    },[]);
    
    return (
        <div className={classes.root}>
            <Grid container justifyContent='center'>
                {/* Button Actions */}
                <Grid item className={classes.left}>
                    <Paper className={classes.paper}>
                        <Grid container item style={{padding: "10px 0px 0px 0px"}}>
                            <Typography variant="h6" style={{paddingLeft: 20}}>Help Topics</Typography>
                        </Grid>
                        <List>
                            {
                                Object.keys(helpTopics).map((t, idx) => (
                                    <ListItem 
                                        key={idx}
                                        dense
                                        onClick={() => setSelectedTopic(helpTopics[t])}
                                        classes={{
                                            selected: classes.listSelected, 
                                            root: classes.listRoot
                                        }}
                                        selected={selectedTopic.topic === helpTopics[t].topic}
                                    >
                                        <ListItemText>
                                            <Typography variant='button' style={{paddingLeft: 20}}>{helpTopics[t].name}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Paper>
                </Grid>
                
                {/* Help Topic Content */}
                <Grid item className={classes.right}>
                    {selectedTopic.comp}
                </Grid>
            </Grid>
        </div>
    )
}
